import { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import icon from './../../assets/svg/empty-state-icon.svg'
import style from './ImageUploader.module.css'
import { useDropzone } from 'react-dropzone';
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const Image = ({ src, onDelete }) => {
    return (<>
        <div className={Object.values(src).length > 0 ? `d-block ${style.border} d-flex justify-content-center align-items-center` : `d-none`}>
            <div className={`${style.image} shadow`} style={{ overflow: 'hidden' }}>
                <div className='text-end mt-1 mx-2'>
                    <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faClose} onClick={()=>onDelete(src)}/>
                </div>
                <div className='img' >
                    <img src={src[0]?.preview} alt="" width='100%' height='auto' />
                </div>
            </div>
        </div>
    </>)
}

const ImageUploader = forwardRef(({ selectedFiles={}, setSelectedFiles }, ref) => {

    const [noOfImageError, setNoOfImageError] = useState(false);
    const [sizeOfImageError, setSizeOfImageError] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        },
        onDrop: (acceptedFiles, fileRejections) => {
            setSizeOfImageError(false)
            fileRejections.forEach(file => {
                file.errors.forEach(err => {
                    if (err.code === 'file-too-large') {
                        setSizeOfImageError(true);
                    }
                })
            })
            const filesArray = acceptedFiles.map((file) => ({ preview: URL.createObjectURL(file), data: file }));
            if ((filesArray?.length) < 2) {
                setSelectedFiles({ ...filesArray })
                // setSelectedFiles((prevImage) => [...prevImage, ...filesArray]);
                setNoOfImageError(false)
            }
            else {
                setNoOfImageError(true);
            }
            acceptedFiles.map((file) => URL.revokeObjectURL(file));
        },
        // maxFiles:10,
        maxSize: 2621440
    });

    const onDelete = (photo) =>{
        // setSelectedFiles((prevImage)=>Object.values(prevImage).filter((img => img !== photo)))
        setSelectedFiles({})
        setNoOfImageError(false)
        setSizeOfImageError(false)
    }

    useImperativeHandle(ref, () => ({
        uploadImage: () => {
            return new Promise((resolve, reject)=> {
                if(Object.values(selectedFiles).length > 0){
                    const formData = new FormData();
                    formData.append('files', selectedFiles[0].data, selectedFiles[0].data.name);
                    axios.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images/profiles`,formData,{withCredentials:true,headers:{'Accept':'application/json','content-type':'multipart/form-data'}}).then((data)=>{
                        resolve(data.data.files[0])
                    }).catch((err)=>{
                        reject(err)
                    })
                } else {
                    
                }
            })
        }
    }))

    return (
        <Row>
            <Col>
                <Form.Group>
                    <Image src={selectedFiles} onDelete={onDelete} />
                    <Form.Label {...getRootProps({
                        onClick: (e) => {
                            e.preventDefault();
                        }
                    })}
                        style={Object.values(selectedFiles)?.length > 0 ? { display: 'none' } : {}} className={`${Object.values(selectedFiles)?.length > 0 ? 'd-none' : ''} px-3 py-4 border-1 d-flex justify-content-center align-items-center gap-2 ${style.border}`}>
                        <img alt="icon" src={icon} />
                        <div className='text-center'>
                            <p className={`m-0 ${style.txt}`}>Drop your file here or <span className={style.browseTxt}>browse</span></p>
                            <span className={`text-secondary ${style.txtSize}`}>Allowed files: (jpeg, jpg, png)</span>
                            <span className={`text-secondary ${style.txtSize}`}>(maximum 2.5 MB)</span>
                            <span className={`text-secondary ${style.txtSize}`}>Only 1 shop logo can be added.</span>
                        </div>
                        <Form.Control {...getInputProps()} />
                    </Form.Label>
                    {
                        sizeOfImageError &&
                        <span style={{ color: 'red' }}>You Try to Add Image with size more then 2.5 MBs.</span>
                    }
                    <br />
                    {
                        noOfImageError &&
                        <span style={{ color: 'red' }}>You Try to Add more then 1 Image</span>
                    }
                </Form.Group>
            </Col>
        </Row>
    );
})

export default ImageUploader;