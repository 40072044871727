import React, { useEffect } from "react";
import axios from "axios";
import { Col, Row, Table, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import customloader from "../../src/assets/images/RollingLoader.gif";
import style from "./../styles/Invoice.module.css";
import logo1 from "../assets/images/logo2.png";
import logo2 from "../assets/images/logo3.png";

const PaymentInvoice = forwardRef((props, ref) => {
  const { printId, ...restProps } = props;
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  const [isIdUndefined, setIsIdUndefined] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      try {
        if ((id && id !== -1) || (printId && printId !== -1)) {
          setIsIdUndefined(false);
          const getData = async () => {
            const res = await axios.get(process.env.REACT_APP_API_URL + `/transactions/payment-invoice/${id ? id : printId}`, { withCredentials: true });
            if (res) {
              setData(res?.data[0]);
              const planData = res.data.map((data) => {
                const item = {
                  "Plan Name": data?.current_plan_name,
                  "Emails Limit": data?.emails_limit,
                  "Billing Frequency": data?.current_plan_billing_frequency,
                  "SMS Limit": data?.sms_limit,
                  "Subscription Type": data?.subscription_type,
                  "Locations Limit": data?.locations_limit,
                  "Plan Duration": new Date(data?.current_plan_start_date).toLocaleDateString() + " to " + new Date(data?.current_plan_end_date).toLocaleDateString(),
                  "MarketPlace Items Limit": data?.marketplace_items_limit,
                };
                return item;
              });
              setItems(planData);
              setIsLoading(false);
            }
          };
          getData();
        } else {
          setIsIdUndefined(true);
        }
      } catch (err) {
        setIsLoading(false);
        setIsIdUndefined(true);
      }
    }, 0);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [id, printId, shop_id]);
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : data?.length === 0 ? (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this invoice</h3>
        </div>
      ) : (
        <Card className={`border-0 ${style.invoiceCard} ${style.formCard} mb-5 mt-5`}>
          <div className={"p-5 laser-invoice"} ref={ref} {...restProps}>
            <div className="d-flex justify-content-between mb-4">
              <h1 className={`mb-3 ${style.invoice}`}>Invoice</h1>

              <div className={`d-flex justify-content-end align-items-center gap-2 mb-2`}>
                <img src={logo2} alt="" className={`${style.logoimg} ${style.logo} `} />
                <img src={logo1} alt="" />
              </div>
            </div>

            <Row className="">
              <Col xs sm="4" md="4" lg="4" className="px-0 ps-3">
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Customer Details
                </p>
                <span
                  className="text-capitalize"
                  style={{
                    color: "#39414A",
                    marginBottom: "0rem",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {data?.shop_owner_name}
                </span>
                <div className={`mb-2 ${style.phn} px-0`}>
                  <p className={`${style.emailphone}`}>
                    <strong>Phone:</strong> {data?.owner_whatsapp_number}
                  </p>
                  <p style={{ whiteSpace: "pre-wrap !important" }} className={`${style.emailphone}`}>
                    <strong>Email:</strong> {data?.owner_email}
                  </p>
                  <p className={`${style.emailphone}`}>
                    <strong>CNIC:</strong> {data?.owner_CNIC}
                  </p>
                </div>
              </Col>
              <Col xs sm="4" md="4" lg="4" className="px-0 ps-3">
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Shop Details
                </p>
                <span
                  className="text-capitalize"
                  style={{
                    color: "#39414A",
                    marginBottom: "0rem",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {data?.shop_name}
                </span>
                <div className={`mb-2 ${style.phn} px-0`}>
                  <p style={{ whiteSpace: "pre-wrap !important" }} className={`${style.emailphone}`}>
                    <strong>Address:</strong> {data?.shop_address}
                  </p>
                </div>
              </Col>

              {data.transaction_date !== null ? (
                <>
                  <Col xs sm="4" md="4" lg="4">
                    <div className={`${style.invoicenum} mb-3`}>
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Payment Date
                      </p>
                      <h5 className={`${style.invoicetxt}`}>{new Date(data.transaction_date).toLocaleDateString()}</h5>
                    </div>
                    <div className={`${style.invoicenum}`}>
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Transaction Number
                      </p>
                      <h5 className={`${style.invoicetxt} fw-2`}>{data?.transaction_id === null ? "N/A" : data?.transaction_id}</h5>
                    </div>
                  </Col>
                </>
              ) : (
                <Col xs sm="4" md="4" lg="4" className={`${style.invoicenum}`}>
                  <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                    Trial Start Date
                  </p>
                  <h5 className={`${style.invoicetxt}`}>{new Date(data.current_plan_start_date).toLocaleDateString()}</h5>
                </Col>
              )}
            </Row>
            <Table style={{ border: "1px solid #DEDFE4", tableLayout: "fixed" }} className={`mb-4 mt-3 ${style.invoiceTable}`}>
              <thead style={{ background: "#F5F6FA" }}>
                <th colSpan={6} className={`ps-3 ${style.tabledescription}`}>
                  Subscription Detail
                </th>
                <th colSpan={6} className={`ps-3 ${style.tabledescription}`}>
                  Benefits Includes
                </th>
              </thead>
              <tbody>
                {Object.entries(items[0])
                  ?.reduce((accumulator, [val, data], index, array) => {
                    if (index % 2 === 0) {
                      accumulator.push(array.slice(index, index + 2));
                    }
                    return accumulator;
                  }, [])
                  .map((pair, index) => (
                    <tr key={index}>
                      {pair.map(([val, data], subIndex) => (
                        <React.Fragment key={subIndex}>
                          <td colSpan={3} className={`ps-2 ${style.tabledescription}`}>
                            {val}:
                          </td>
                          <td colSpan={3} className={`ps-2 ${style.tabledescription} border-end`}>
                            {data === "nil" || data === null ? "--" : data}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Table style={{ border: "1px solid #DEDFE4", tableLayout: "fixed" }} className={`mb-4 ${style.invoiceTablePayments}`}>
              <thead style={{ background: "#F5F6FA" }}>
                <th className={`ps-2 ${style.tabletxt}`}>Plan Amount</th>
                <th className={`${style.tabletxt}`}>Arrears</th>
                {Number(data?.discount) > 0 && <th className={`${style.tabletxt}`}>Discount</th>}
                <th className={`${style.tabletxt}`}>Total Payable</th>
              </thead>
              <tbody>
                <tr>
                  <td className={`ps-2 ${style.tabledescription}`}>{Number(data?.plan_amount).toLocaleString("en-IN")}</td>
                  <td className={`${style.tabletxt}`}>{data?.arrears === null ? 0 : Number(data?.arrears).toLocaleString("en-IN")}</td>
                  {Number(data?.discount) > 0 && <td className={`${style.tabledescription}`}>{Number(data?.discount).toLocaleString("en-IN")}</td>}
                  <td className={`${style.tabledescription}`}>{Number(data?.total_payable).toLocaleString("en-IN")}</td>
                </tr>
              </tbody>
            </Table>
            <Row className="">
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}>Total Amount</span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}> {Number(data?.total_payable).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row className="">
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}>Amount Received</span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}> {Number(data?.amount_paid).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row style={{ display: "contents" }}>
              <div className="" style={{ borderTop: "1px solid #DEDFE4" }}></div>
            </Row>
            <Row className="">
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}>Remaining Amount</span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}> {Number(data?.remaining_amount).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row style={{ display: "contents" }}>
              <div className="mb-4" style={{ borderTop: "1px solid #DEDFE4" }}></div>
            </Row>
            <p className={`${style.invoiceterms} m-0`}>Invoice terms</p>

            {Number(data?.remaining_amount) === 0 ? (
              <>
                <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                  The Shopkeeper has <b className="text-success fs-5">PAID</b> the amount.
                </p>
              </>
            ) : (
              <>
                <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                  The Shopkeeper still needs to pay <b className="text-danger fs-5">{Number(data?.remaining_amount).toLocaleString("en-IN")}</b> to clear the invoice.
                </p>
              </>
            )}
            <div className="mt-2" style={{ borderTop: "1px solid #DEDFE4" }}></div>
            <div style={{ fontSize: "11px" }} className="d-flex justify-content-between text-muted mx-5">
              <div className="">https://techbazaar.io</div>
              <div className="">+92 300 0444448</div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
});

export default PaymentInvoice;
