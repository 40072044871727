import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// shopkeepres

export function getShopKeepersOnTrial(data) {
  const { page, size, ...remData } = data;
  return api.post(`/shops/get-shopkeeper-on-trial${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}

export function getShopKeepersOnPayment(data) {
  const { page, size, ...remData } = data;
  return api.post(`/shops/get-shopkeeper-on-payment${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}

export function getPlansDropDown(data) {
  return api.post(`/subscriptions/get/plans`, data, { withCredentials: true });
}

export function getPlanByShopId(id) {
  return api.get(`/plans/get/${id}`, { withCredentials: true });
}

export function fetchAllSellerRegistrationRequests(data) {
  const { size, page, search, filters, user_id, ...remData } = data;
  const headers = {
    filters: JSON.stringify(filters),
  };
  return api.get(`/become-seller/${user_id}?page=` + page + `&size=` + size + `&search=` + search, { headers, withCredentials: true });
}
const orderByFormatForQueryString = (obj) => {
  const [[key, value]] = Object.entries(obj);
  return `${key}:${value}`;
};
export function fetchAllConsumableItems(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
  });
  return api.get(`/addons/pricing/consumable-item${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}

export function getDropdownShops(id) {
  return api.get(`shops/for-dropdown`, { withCredentials: true });
}
