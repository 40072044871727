import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, Dropdown } from "react-bootstrap";
import logo from "./../assets/images/logo2.png";
import logo2 from "./../assets/images/logo3.png";
import style from "./../styles/Navbar.module.css";
import signOut from "../assets/svg/signout.svg";
import { authActions } from "../Features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import profile from "./../assets/images/profile.png";
import bellIcon from "../assets/svg/bellIcon.svg";
import bellActiveIcon from "../assets/svg/bellActiveIcon.svg";
import NotificationDropdown from "./NotificationDropdown/NotificationDropdown";
import { useEffect, useState } from "react";

const Topbar = ({ toggle, isSandwichOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.notification);

  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [activeBell, setActiveBell] = useState(false);
  // const userName = user.user_full_name.charAt(0);
  const userName1 = user.user_full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
  const logout = () => {
    localStorage.removeItem("refreshToken");
    dispatch(authActions.logout());
    navigate("/login");
  };
  useEffect(() => {
    if (pathname === "/notifications") {
      setActiveBell(true);
    } else {
      setActiveBell(false);
    }
  }, [pathname]);
  const handleNotificationDropdown = () => {
    if (window.innerWidth < 576) {
      navigate("/notifications");
      setNotificationDropdown(false);
    } else {
      setActiveBell((prev) => !prev);
      setNotificationDropdown((prev) => !prev);
    }
  };

  return (
    <Navbar className="shadowMain position-fixed w-100 mb-5" style={{ zIndex: 5 }} bg="white">
      <div className="px-4 d-flex justify-content-between align-items-center w-100">
        <div className={`py-2 px-2 d-flex justify-content-center align-items-center gap-5 ${style.navItem}`}>
          <div>
            <img src={logo2} className={`me-2 ${style.logo}`} alt="logo" />
            {!isSandwichOpen && <img src={logo} className="me-4 d-none d-md-inline" alt="logo" />}
          </div>
          <div style={{ width: "25px" }}>
            <FontAwesomeIcon role="button" icon={faBars} onClick={() => toggle()} />
          </div>
        </div>
        <div className={`d-flex align-items-center justify-content-end`}>
          <div style={{ position: "relative" }} className="me-2">
            <div style={{ width: "25px" }} className="d-inline d-flex justify-content-center cursor-pointer notificationButton" onClick={pathname === "/admin-notifications" ? null : handleNotificationDropdown}>
              <img
                style={{
                  pointerEvents: "none",
                }}
                width="19px"
                height="22px"
                src={activeBell ? bellActiveIcon : bellIcon}
                alt="notification Icon"
                className="notificationButton"
              />
              <div className={`${style.notificationBadge} notificationButton`}>
                <span className={`${style.notificationCount} notificationButton`}>{+notification.notificationsCount > 99 ? "99+" : notification.notificationsCount}</span>
              </div>
            </div>

            {notificationDropdown && (
              <div
                style={{
                  position: "absolute",
                  top: "30px",
                  left: 0,
                  width: "100%",
                }}
              >
                <NotificationDropdown
                  show={notificationDropdown}
                  handleClose={() => {
                    setActiveBell(false);
                    setNotificationDropdown(false);
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <Dropdown className={`d-flex d-lg-inline d-md-inline justify-content-end ${style.toggle}`}>
              <Dropdown.Toggle variant="light" className={`border-0 p-0 text-secondary `} id="dropdown-basic" style={{ background: "transparent" }}>
                <div className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>
                  {/* <img
                                    width="36px"
                                    height="36px"
                                    className="rounded-circle"
                                    src={profile}
                                    alt="profile"
                                /> */}
                  {/* <span className={`rounded-circle ${style.name}`}>{userName}</span> */}
                  {userName1}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="rounded-0 shadow" style={{ left: "-6rem", top: "28px", border: "1px solid rgba(222, 223, 228, 1)" }}>
                <Dropdown.Item className="purple" href="" eventKey="true">
                  <span className={`${style.signOut} d-flex justify-content-start gap-2`} onClick={logout}>
                    <img src={signOut} alt="signout" />
                    {"Sign Out"}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default Topbar;
