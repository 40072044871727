import search from "../assets/svg/search.svg";
import { Form, FormControl } from "react-bootstrap";
import style from "./../styles/SearchInput.module.css";

const SearchInput = ({
  styles,
  searchlogo,
  bordered,
  value,
  openSearchBar,
  handleChange,
}) => {
  //{value, onChange ,...props}
  const handle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <>
      <Form className="position-relative d-inline" onSubmit={handle}>
        <FormControl
          type="search"
          placeholder="Search"
          className={`text-secondary ${style.searchInput}`}
          aria-label="Search"
          style={styles}
          onChange={handleChange}
          value={value}
        />
        <div className={`${style.searchIcon}`}>
          {searchlogo ? <img src={search} alt="search" /> : ""}
        </div>
      </Form>
      {/* <Form
        className="d-flex position-relative d-lg-none justify-content-end h-100"
        onSubmit={handle}
      >
        <FormControl
          type="search"
          placeholder="Search"
          className={`text-secondary d-none ${style.searchInput}`}
          aria-label="Search"
          style={styles}
        />
        <div
          className={`${style.searchIcon} ${
            bordered ? style.iconBorder : ""
          } d-flex `}
          onClick={openSearchBar}
        >
          {searchlogo ? <img src={search} alt="search" /> : ""}
        </div>
      </Form> */}
    </>
  );
};

export default SearchInput;
export const SearchMobleInput = ({
  styles,
  searchlogo,
  bordered,
  value,
  openSearchBar,
  handleChange,
}) => {
  const handle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Form className="position-relative  d-lg-inline" onSubmit={handle}>
      <FormControl
        type="search"
        placeholder="Search"
        className={`text-secondary ${style.searchInput}`}
        aria-label="Search"
        style={styles}
        onChange={handleChange}
        value={value}
      />
      <div className={`${style.searchIcon} d-flex`}>
        {searchlogo ? <img src={search} alt="search" /> : ""}
      </div>
    </Form>
  );
};
