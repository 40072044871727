import React from 'react';
import style from './../styles/RegisterShop.module.css'
import { Button, Form, Row, Col } from 'react-bootstrap'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { default as ReactSelect } from "react-select";
import Loader from '../Components/Loader';
import PhoneInput from '../Components/PhoneInput/PhoneInput';
import CnicInput from '../Components/CnicInput/CnicInput';

const EditShop = ({ shopId, close, toggle }) => {
    const [shop_name, setShopName] = useState('');
    const [shop_owner_name, setShopOwnerName] = useState('');
    const [shop_address, setShopAddress] = useState('');
    const [owner_CNIC, setOwnerCNIC] = useState('');
    const [owner_whatsapp_number, setOwnerWhatsappNumber] = useState('');
    const [owner_backup_phone_number, setOwnerBackupPhoneNumber] = useState('');
    const [province_name, setProvince] = useState([]);
    const [provinceEdit, setProvinceEdit] = useState([]);
    const [province_id, setProvinceid] = useState();
    const [city, setCity] = useState([]);
    const [cityEdit, setCityEdit] = useState([]);
    const [city_id, setCityid] = useState();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isClicked, setIsclicked] = useState(false);
    const [daysField, setDaysField] = useState([]);

    const [validated, setValidated] = useState(false)

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + `/shops/${shopId}`, { withCredentials: true }).then((res) => {
            setShopName(res.data[0].shop_name);
            setShopOwnerName(res.data[0].shop_owner_name);
            setShopAddress(res.data[0].shop_address);
            setOwnerWhatsappNumber(res.data[0].owner_whatsapp_number);
            setOwnerBackupPhoneNumber(res.data[0].owner_backup_phone_number);
            setOwnerCNIC(res.data[0].owner_CNIC);
            setProvinceid(res.data[0].province_id);
            setCityid(res.data[0].city_id);
            setProvinceEdit({ value: res.data[0].province_id, label: res.data[0].province_name });
            setCityEdit({ value: res.data[0].city_id, label: res.data[0].city_name });
            const days = JSON.parse(res.data[0].shop_working_days);
            const shopDays = days.map((day) => day);
            setDaysField(shopDays.map((day) => ({ value: day, label: day })));
        }).catch(err => {
            throw new Error(err);
        })
    }, [shopId]);

    const daysOptions = [
        { value: "mon", label: "Mon" },
        { value: "tue", label: "Tue" },
        { value: "wed", label: "Wed" },
        { value: "thur", label: "Thu" },
        { value: "fri", label: "Fri" },
        { value: "sat", label: "Sat" },
        { value: "sun", label: "Sun" }
    ];
    useEffect(() => {
        const getprovince = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "/location/get-province", { withCredentials: true });
                const data = res.data.map((p) => ({ value: p.province_id, label: p.province_name }));
                setProvince(data);
            } catch (error) {
                throw new Error(error);
            }
        }
        getprovince();
    }, []);

    useEffect(() => {
        const getcity = (id) => {
            axios.get(process.env.REACT_APP_API_URL + `/location/get-city/${id}`, { withCredentials: true }).then((res) => {
                const data = res.data.map((c) => ({ value: c.city_id, label: c.city_name }));
                setCity(data);
                // setCityid(data[0].value)
                // setCityEdit({})
            }).catch((err) => {
                throw new Error(err);
            })
        }
        if (province_id) {
            getcity(province_id);
        }
    }, [province_id]);

    const handleShopName = (e) => {
        setShopName(e.target.value.replace(/[^\w\s]/gi, ""))
    }
    async function submit(e) {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        try {
            setValidated(true)
            if (daysField.length > 0 && province_id > 0 && city_id > 0 && shop_name !== '' && shop_owner_name !== '' && shop_address !== '' && owner_CNIC !== '' && owner_whatsapp_number !== '') {
                setIsclicked(true);
                const obj = {
                    shop_name, shop_owner_name, shop_address, owner_CNIC,
                    owner_whatsapp_number, owner_backup_phone_number, province_id: province_id, city_id: city_id, shop_working_days: JSON.stringify(daysField.map((obj) => obj.value))
                }
                const res = await axios.patch(process.env.REACT_APP_API_URL + `/shops/${shopId}`, obj, { withCredentials: true });
                setSuccess("Shop Updated Successfully.");
                setIsclicked(false);
                setValidated(false);
                toggle((prev) => !prev);
                if (res.data) {
                    setTimeout(() => close(), 1000);
                    setError("")
                }
            }
        }
        catch (error) {
            setIsclicked(true)
            setError(error.response.data.error.message)
            setIsclicked(false)
        }
    }
    return (

        <Form noValidate validated={validated} onSubmit={submit}>
            {error && <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>}
            {success && <p className="text-success p-2 text-center my-2 rounded-1">*{success}</p>}
            <Row>
                <Form.Group className='my-2' as={Col} lg='6' md='6' sm='12' controlId="formBasicShopName">
                    <label>Shop Name <span className='text-danger'>*</span></label>
                    <Form.Control className="p-2" name='shop_name' type="text" placeholder="Shop Name" value={shop_name} onChange={(e) => handleShopName(e)} required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Enter Shop Name
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-2' as={Col} lg='6' md='6' sm='12' controlId="formBasicName">
                    <label>Owner Name <span className='text-danger'>*</span></label>
                    <Form.Control className="p-2" name="shop_owner_name" type="text" placeholder="Owner Name" value={shop_owner_name} onChange={(e) => setShopOwnerName(e.target.value)} required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Enter Owner Name
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-2' as={Col} lg='12' md='12' sm='12' controlId="formBasicAddress">
                    <label>Address <span className='text-danger'>*</span></label>
                    <Form.Control className="p-2" name="address" type="text" placeholder="Type Address" value={shop_address} onChange={(e) => setShopAddress(e.target.value)} required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Enter Address
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='my-2' as={Col} lg='6' md='6' sm='12'>
                    <label>Province <span className='text-danger'>*</span></label>
                    {/* <ReactSelect placeholder="Select Province" options={province_name} onChange={(e)=> setProvinceEdit(e)} value={provinceEdit} /> */}
                    <Form.Select onChange={(e) => setProvinceid(e.target.value)} value={province_id}>
                        <option value="" disabled selected>Select Province</option>
                        {
                            province_name.map((data) => (
                                <option value={data.value}>{data.label}</option>
                            ))
                        }
                    </Form.Select>
                    <Form.Control className="p-2" name="province" hidden type="text" value={Object.values(provinceEdit).length > 0 ? provinceEdit?.value : null} onChange={() => { }} required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Select Province
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-2' as={Col} lg='6' md='6' sm='12' >
                    <label>City <span className='text-danger'>*</span></label>
                    {/* <ReactSelect placeholder="Select City" options={city} onChange={(e)=> setCityEdit(e)} value={cityEdit} /> */}
                    <Form.Select onChange={(e) => setCityid(e.target.value)} value={city_id}>
                        <option value="" disabled selected>Select City</option>
                        {
                            city.map((data) => (
                                <option value={data.value}>{data.label}</option>
                            ))
                        }
                    </Form.Select>
                    <Form.Control className="p-2" name="city" hidden type="text" value={Object.values(cityEdit).length > 0 ? cityEdit?.value : null} onChange={() => { }} required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Select City
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-2' as={Col} lg='6' md='6' sm='12' controlId="formBasicCNIC">
                    <label>CNIC Number <span className='text-danger'>*</span></label>
                    <CnicInput value={owner_CNIC} onChange={(e) => setOwnerCNIC(e.target.value)} placeholder="XXXXX-XXXXXXX-X" required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Enter CNIC
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='my-2' as={Col} lg='6' md='6' sm='12' controlId="formBasicNumber">
                    <label>Whatsapp Number <span className='text-danger'>*</span></label>
                    <PhoneInput placeholder="+92-3XX-XXXXXXX" value={owner_whatsapp_number} onChange={(e) => setOwnerWhatsappNumber(e.target.value)} required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Enter Correct Whatsapp Number
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-2' as={Col} lg='6' md='6' sm='12' controlId="formBasicEmail">
                    <label>Backup Number  <span className='text-secondary'>(Optional)</span></label>
                    <PhoneInput className="p-2" type="number" placeholder="+92-3XX-XXXXXXX" value={owner_backup_phone_number} onChange={(e) => setOwnerBackupPhoneNumber(e.target.value)} pattern="^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$" />
                    <Form.Control.Feedback type="invalid" >
                        * Please Enter Correct Number
                    </Form.Control.Feedback>
                </Form.Group>
                <Col className='mt-2 mb-4' lg='6' md='6' sm='12'>
                    <label className='mb-1'>Shop Working Days <span className='text-danger'>*</span></label>
                    <ReactSelect
                        classNamePrefix='react-select-container'
                        options={daysOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(e) => setDaysField(e)}
                        value={daysField}
                        name="shop_working_days"
                    />
                    <Form.Control className="p-2" name="workingDays" hidden type="text" value={daysField.length > 0 ? daysField : ""} onChange={() => { }} required />
                    <Form.Control.Feedback type="invalid" >
                        * Please Select Shop Working Days
                    </Form.Control.Feedback>
                </Col>
            </Row>
            <div className={`mb-4 ${style.fullWidth}`}>
                <Button className="w-100" type="submit" disabled={isClicked} >{isClicked ? <Loader /> : "Update Shop"}</Button>
            </div>
        </Form>
    )
}
export default EditShop;