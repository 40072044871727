import FormCard from "../Components/FormCard";
import style from "./../styles/Login.module.css";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Features/authSlice";
import Loading from "../Components/Loading/Loading";
import Loader from "./../Components/Loader";

const Login = () => {
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [user_email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [appError, setErrors] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setTimeout(() => setLoading(false), 2000);
    };
    loadData();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, error, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      setErrors(message);
      setIsClicked(false);
    }
    if (user) {
      setIsClicked(false);
      setTimeout(() => navigate("/"), 1500);
    }
  }, [user, error, message, navigate]);
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let emailValidate = user_email.length > 0 && !emailFormat.test(user_email) ? false : true;
  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);

    // if (!user_email || !password) {
    //   setErrors("Fields cannot be empty");
    // } else {
    try {
      if (emailValidate && password) {
        setIsClicked(true);
        const userData = { user_email, password };
        const data = await dispatch(login(userData));
        setIsClicked(false);
      }
    } catch (error) {
      setIsClicked(false);
    }
    // }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={`d-flex justify-content-center align-items-center flex-column ${style.wrapper}`}>
          <div className="d-flex justify-content-center mt-2 ">
            <h2>Sales Portal</h2>
          </div>
          <FormCard>
            <h4 className="mt-5 mb-4 text-center fw-bold">Welcome Back</h4>
            {appError && <span className="text-danger my-2 text-center">*{appError}</span>}
            <Form validated={validated} noValidate onSubmit={onSubmit}>
              <Form.Group className="mb-4" md="4" controlId="validationCustomEmail">
                <Form.Control type="email" name="user_email" placeholder="Enter Email" className="p-2" aria-describedby="inputGroupPrepend" required onChange={(e) => setUserEmail(e.target.value)} isInvalid={validated && !emailValidate} />
                <Form.Control.Feedback type="invalid">* Invalid Email Format</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className={`mb-2 ${style.passwordInput}`} md="4" controlId="validationCustomPassword">
                <Form.Control className="p-2" name="password" type={showPassword ? "text" : "password"} placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} required />
                <div className={`${style.passwordField}`}>
                  <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
                </div>
                <Form.Control.Feedback type="invalid">* Invalid Password Field</Form.Control.Feedback>
              </Form.Group>
              {/* <div className="d-flex justify-content-end mb-4">
                                <Link className="link" to="/forget-password">Forget Password?</Link>
                            </div> */}
              <div className="mb-4 mt-4">
                <Button className="w-100" type="submit">
                  {isClicked ? <Loader /> : "Log In"}
                </Button>
              </div>
            </Form>
          </FormCard>
        </div>
      )}
    </>
  );
};

export default Login;
