import style from "./../../styles/Sidebar.module.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const NavOption = ({
  setIsSandwichOpen,
  isSandwichOpen,
  title,
  icon,
  path,
  identifier,
  isSubNav,
  active,
  setActive,
  isOpen,
  setIsOpen,
  subnavigation,
}) => {
  const location = useLocation();

  return (
    <div className={`${style.Sidebartooltip} `}>
      <NavLink
        to={path}
        className={`${style.sideLink} my-1 d-block`}
        onClick={() => {
          if (window.innerWidth < 920) {
            //  console.log("asdf")
            setIsSandwichOpen((prev) => !prev);
          }
          setActive(identifier);
          if (isOpen && identifier === active) {
            // console.log(window.innerWidth)
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
        style={() =>
          location.pathname === identifier
            ? {
                backgroundColor: "rgba(241, 244, 250, 1)",
                color: "rgba(89, 50, 234, 1)",
                textDecoration: "none",
              }
            : { color: "#545e6f", textDecoration: "none" }
        }
      >
        <div
          className={`d-flex  ${subnavigation && "ms-4 "} ${
            isSandwichOpen
              ? "justify-content-center"
              : "gap-2 justify-content-between"
          } align-items-center`}
        >
          <div className="d-flex align-items-center gap-2">
            {icon}
            {!isSandwichOpen && title}
          </div>
          {!isSandwichOpen && isSubNav && (
            <FontAwesomeIcon
              icon={
                active === identifier && isOpen ? faChevronUp : faChevronDown
              }
            />
          )}
        </div>
        {isSandwichOpen && <span className={style.tooltipClose}>{title}</span>}
      </NavLink>
    </div>
  );
};

export default NavOption;
