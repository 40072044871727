import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Form, Overlay, Row, Tooltip } from "react-bootstrap";
import RollingLoader from "../../assets/images/RollingLoader.gif";
import ReactSelect from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../Pages/MyShopkeepers/Transaction/ConfirmationModal";
const ShiftToPaidPlanModal = ({ close, toggle, id }) => {
  const userId = useSelector((state) => state.auth.user.user_id);
  const [plan, setPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [plansRes, setPlanRes] = useState([]);
  const [paymentType, setPaymentType] = useState({});
  const imageUpload = useApi(postapi.UPloadImage);
  const [request, setRequest] = useState([]);

  const [isClicked, setIsClicked] = useState(false);

  const [arrears, setArrears] = useState(0);
  const [error, setError] = useState("");
  // const dropdownShops = useApi(api.getDropdownShops);
  // const dropdownPlans = useApi(api.getPlansDropDown);
  const shiftToPaid = useApi(postapi.ShiftToPaidPlan);
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));
  const [planAmount, setPlanAmount] = useState();
  const [paidAmount, setPaidAmount] = useState();
  const [transDate, setTransDate] = useState(new Date().toISOString().split("T")[0]);
  const [billingPeriod, setBillingPeriod] = useState({
    value: "monthly",
    label: "Monthly",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectImage, setSelectImag] = useState();
  const [checkReq, setCheckReq] = useState(0);
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectImageURL, setSelectImagURL] = useState();
  const [remaining, setRemaining] = useState();
  const [discount, setDiscount] = useState(0);
  const [payable, setPayable] = useState(0);
  const [transactionId, settransactionId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [Dshow, setDShow] = useState(false);

  const target = useRef(null);
  const discountTarget = useRef(null);

  useEffect(() => {
    const data = plansRes.filter((data) => data?.plan_id === selectedPlan.value)[0];
    setPlanAmount(data?.monthly_grand_total);
    if (billingPeriod.value === "monthly") {
      setPayable(data?.monthly_grand_total);
    } else if (billingPeriod.value === "quarterly") {
      setPayable(data?.quarterly_grand_total);
      setPlanAmount(data?.quarterly_grand_total);
    } else {
      setPayable(data?.yearly_grand_total);
      setPlanAmount(data?.yearly_grand_total);
    }
  }, [planAmount, selectedPlan, arrears, billingPeriod]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/plans/get`, { planType: "fixed" }, { withCredentials: true });
        setPlanRes([...res.data, { value: "custom", label: "Custom" }]);
        const plandResData = res.data?.map((data) => ({ value: data.plan_id, label: data.plan_name }));
        setPlan([...plandResData]);
      } catch (error) {
        throw new Error(error);
      }
    };
    fetchPlans();
  }, [id]);
  //   useEffect(() => {
  //     const fetchRequests = async () => {
  //       try {
  //         setIsLoading(true);
  //         const data = await getReq.request({
  //           shop_id: id,
  //           user_id: userId,
  //           request_type: requestType,
  //           status: "in_progress",
  //         });

  //         setCheckReq(data?.data?.length);
  //         setIsLoading(false);
  //       } catch (error) {}
  //     };
  //     const fetchArear = async () => {
  //       try {
  //         const data = await getarear.request(id);
  //         if (data) {
  //           setArrears(data.data.arrears);
  //         } else if (getarear?.error?.data) {
  //           setArrears(0);
  //         }
  //       } catch (error) {}
  //     };
  //     fetchArear();

  //     fetchRequests();
  //   }, []);

  // const getRequest = async () => {
  //   return new Promise((resolve, reject) => {
  //     getReq
  //       .request({
  //         shop_id: id,
  //         user_id: userId,
  //         request_type: requestType,
  //         status: "in_progress",
  //       })
  //       .then((res) => {
  //         const result = res.data.filter((cat) => cat.request_title.concat("-", cat.request_title).toLowerCase().includes(filterInput.toLowerCase()));
  //         const getData = result.map((cat) => ({
  //           value: cat,
  //           label: (
  //             <>
  //               <strong className="fs-14" name={cat.request_title}>
  //                 {cat.id}-{cat.request_title}
  //               </strong>
  //             </>
  //           ),
  //           name: cat.request_title,
  //         }));
  //         resolve(getData);
  //       })
  //       .catch((err) => {
  //         reject("err" + err);
  //       });
  //   });
  // };
  // const getPlans = async () => {
  //   return new Promise((resolve, reject) => {
  //     dropdownPlans
  //       .request({
  //         plan_type: "fixed",
  //         shop_id: id,
  //         is_active: true,
  //       })
  //       .then((res) => {
  //         const result = res.data.filter((cat) => cat.plan_name.concat("-", cat.plan_name).toLowerCase().includes(filterInput.toLowerCase()));
  //         const getData = result.map((cat) => ({
  //           value: cat,
  //           label: (
  //             <>
  //               <strong className="fs-14" name={cat.plan_name}>
  //                 {cat.plan_name}
  //               </strong>
  //             </>
  //           ),
  //           name: cat.plan_name,
  //         }));
  //         resolve(getData);
  //       })
  //       .catch((err) => {
  //         reject("err" + err);
  //       });
  //   });
  // };
  useEffect(() => {
    if (arrears || discount) {
      setPayable(Number(arrears) + Number(planAmount) - Number(discount));
    } else if (arrears) {
      setPayable(Number(arrears) + Number(planAmount));
    } else {
      setPayable(planAmount);
    }
    setRemaining(Number(payable) - Number(paidAmount));
  }, [arrears, planAmount, plan, request, discount, payable, billingPeriod, paidAmount]);
  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    if (selectedPlan && planAmount && paidAmount && paymentType?.value && (paymentType?.value === "transfer" ? transactionId && transDate && selectImage : true)) {
      // if (plan && planAmount && paidAmount && transactionId && transDate) {
      if (paidAmount > payable && discount < payable) {
        setPaidCheck(true);
      } else {
        setShowConfirmationModal(true);
      }
    }
  }
  async function submit(e) {
    setShowConfirmationModal(true);
    e.preventDefault();
    let imgData;
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    try {
      setValidated(true);
      if (selectImage) {
        imgData = await imageUpload.request(selectImage);
      }
      const [date, time] = new Date().toISOString().split("T");
      const curDate = date.split("-");
      const curTime = time.split(".")[0].replaceAll(":", "");
      const dateB32 = Number(curDate[2] + curDate[1] + curDate[0])
        .toString(32)
        .toUpperCase();
      const timeB32 = Number(curTime).toString(32).toUpperCase();
      const dateNow = new Date().toISOString().split("T")[0];
      const timeNow = new Date().toLocaleTimeString().split(" ")[0];
      const currentDate = dateNow + " " + timeNow;
      if (selectedPlan && planAmount && paidAmount && paymentType.value && (paymentType.value === "transfer" ? transactionId && transDate && selectImage : true)) {
        setIsClicked(true);
        const obj = {
          shop_id: id,
          transaction_date: transDate ? transDate : currentDate,
          plan_id: selectedPlan.value,
          plan_amount: planAmount,
          arrears: arrears,
          discount: discount,
          total_payable: payable,
          amount_paid: paidAmount,
          remaining_amount: remaining,
          remaining_amount: Number(payable) - Number(paidAmount),
          transaction_image_url: imgData ? imgData.data.files[0] : null,
          transaction_logged_by: userId,
          transaction_id: transactionId ? transactionId : id + userId + dateB32 + timeB32,
          billing_period: billingPeriod.value,
        };
        const res = await shiftToPaid.request(obj);
        toggle((prev) => !prev);
        toast.success("Plan Shifted successfully ", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        close();
        setIsClicked(false);
        setValidated(false);
        close();
        setShowConfirmationModal(false);
      } else {
        setIsClicked(false);
      }
    } catch (error) {
      // setError(error.response.data.error.message);
      setIsClicked(false);
    }
  }
  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <ToastContainer />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={RollingLoader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Form.Group className="" as={Col} lg="6" md="6" sm="12">
                <label className="mb-1">
                  Plan <span className="text-danger">*</span>
                </label>
                <ReactSelect
                  className="text-capitalize"
                  placeholder="Select Plan"
                  value={Object.keys(selectedPlan).length > 0 ? selectedPlan : null}
                  searchable={true}
                  options={plan}
                  onChange={(e) => {
                    setSelectedPlan(e);
                  }}
                />
                <Form.Control className="p-2" name="plan" hidden type="text" value={Object.keys(selectedPlan).length > 0 ? selectedPlan : null} onChange={() => {}} required />
                <Form.Control.Feedback type="invalid">* Please Select Plan</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12">
                <label>
                  Billing Period <span className="text-danger">*</span>
                </label>
                <ReactSelect
                  selectClassName={"bg-white"}
                  defaultValue={billingPeriod}
                  value={Object.keys(billingPeriod)?.length > 0 ? billingPeriod : null}
                  searchable={false}
                  options={[
                    { value: "monthly", label: "Monthly" },
                    { value: "quarterly", label: "Quarterly" },
                    { value: "yearly", label: "Yearly" },
                  ]}
                  onChange={(e) => {
                    setBillingPeriod(e);
                  }}
                />
                <Form.Control className="p-2" name="province" hidden type="text" value={Object.keys(billingPeriod)?.length > 0 ? billingPeriod?.value : null} onChange={() => {}} required />
                <Form.Control.Feedback type="invalid">* Please Select plan</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Plan Amounts <span className="text-danger">*</span>
                </label>
                <Form.Control value={Math.round(planAmount)} type="number" placeholder="" disabled />
                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Arears <span className="text-danger">*</span>
                </label>
                <Form.Control value={arrears ? arrears : 0} type="number" placeholder="Rs.1000" disabled />
                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>Discount</label>
                <Overlay target={discountTarget.current} show={Dshow} placement="right">
                  {(props) => (
                    <Tooltip style={{ background: "#ffecb5" }} id="overlay-example" {...props}>
                      Discount must be less than payable
                    </Tooltip>
                  )}
                </Overlay>
                <Form.Control
                  href="overlay-example"
                  value={discount}
                  ref={discountTarget}
                  name="discount"
                  onChange={(e) => {
                    setDiscount(e.target.value.replace(/\D/g, ""));
                    if (Number(planAmount) + Number(arrears) >= Number(e.target.value)) {
                      setPaidCheck(false);
                      setDiscount(e.target.value.replace(/\D/g, ""));
                    } else {
                      setDiscount("");
                      setDShow(true);
                      setTimeout(() => {
                        setDShow(false);
                      }, 2000);
                      setPaidCheck(true);
                    }
                  }}
                  type="number"
                  placeholder="Rs.1000"
                />
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Payable <span className="text-danger">*</span>
                </label>
                <Form.Control value={Math.round(payable)} type="number" placeholder="Rs.1000" disabled />
                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Paid Amount <span className="text-danger">*</span>
                </label>
                <Form.Control
                  value={paidAmount}
                  ref={target}
                  placeholder="Rs.1000"
                  required
                  max={Number(payable)}
                  onChange={(e) => {
                    if (Number(payable) >= Number(e.target.value)) {
                      setPaidCheck(false);
                      setPaidAmount(e.target.value);
                    } else {
                      setShow(true);
                      setTimeout(() => {
                        setShow(false);
                      }, 2000);
                      setPaidCheck(true);
                    }
                  }}
                />

                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>Payment Type</label>
                <ReactSelect
                  selectClassName={"bg-white"}
                  defaultValue={paymentType}
                  value={Object.keys(paymentType)?.length > 0 ? paymentType : null}
                  searchable={false}
                  options={[
                    { value: "cash", label: "Cash" },
                    { value: "transfer", label: "Transfer" },
                  ]}
                  onChange={(e) => {
                    setPaymentType(e);
                  }}
                  required
                />
                <Form.Control className="p-2" name="plan" hidden type="text" value={Object.keys(paymentType).length > 0 ? paymentType : null} onChange={() => {}} required />
                <Form.Control.Feedback type="invalid">* Please Select Payment Type</Form.Control.Feedback>
              </Form.Group>
              {paymentType?.value === "transfer" && (
                <>
                  <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Id <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={transactionId}
                      name="contact_person_name"
                      onChange={(e) => {
                        settransactionId(e.target.value);
                      }}
                      placeholder="Enter transaction Id"
                      required={paymentType?.value === "transfer" ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">* Please Enter Id.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Date<span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={transDate}
                      onChange={(e) => {
                        setTransDate(e.target.value);
                      }}
                      required={paymentType?.value === "transfer" ? true : false}
                      disabled={true}
                      type="date"
                    />
                    <Form.Control.Feedback type="invalid">* Please select Date.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Upload Image<span className="text-danger">*</span>
                    </label>
                    <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} required={paymentType?.value === "transfer" ? true : false} />
                    <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              <Col className="mt-2 mb-4" lg="12" md="6" sm="12"></Col>
            </Row>
            <div
            //   className={`mb-4 ${style.fullWidth}`}
            >
              <Button className="w-100" type="submit">
                {"Shift Plan"}
              </Button>
            </div>
          </Form>

          <ConfirmationModal
            submit={submit}
            isClicked={isClicked}
            planType={selectedPlan.label}
            image={selectImageURL}
            planAmount={planAmount}
            remaining={remaining}
            payable={payable}
            arrears={arrears}
            discount={discount}
            transactionId={transactionId}
            paidAmount={paidAmount}
            transDate={transDate}
            billingPeriod={billingPeriod.value}
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        </div>
      )}
    </>
  );
};

export default ShiftToPaidPlanModal;
