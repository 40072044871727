import React, { useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import Extension from "./Extension";
import ConsumableAddons from "./ConsumableAddons";

const AddonsModal = ({ show, toggle, close, data }) => {
  const consumableRef = useRef();
  const extensionRef = useRef();
  const [addonsType, setAddonsType] = useState({ value: "extension", label: "Extension" });
  const [clicked, setClicked] = useState(false);
  const [selected, setSelected] = useState(null);
  const [addons, setAddons] = useState({
    marketplace_items_limit: {
      quantity: "",
      whole_price: 0,
    },
    emails_limit: {
      quantity: "",
      whole_price: 0,
    },
    sms_limit: {
      quantity: "",
      whole_price: 0,
    },
  });
  const handleClick = (e) => {
    try {
      if (addonsType.value === "extension") {
        if (clicked) {
          extensionRef.current.AddRequest(e);
        } else {
          setClicked(true);
        }
      } else {
        consumableRef.current.AddRequest(e);
      }
    } catch (error) {}
  };
  const hideModal = () => {
    setClicked(false);
    setAddonsType({ value: "extension", label: "Extension" });
    setSelected(null);
    setAddons({
      marketplace_items_limit: {
        quantity: "",
        whole_price: 0,
      },
      emails_limit: {
        quantity: "",
        whole_price: 0,
      },
      sms_limit: {
        quantity: "",
        whole_price: 0,
      },
    });
    close();
  };
  function validateLimits(limits) {
    return Object.values(limits).some((limit) => limit.quantity !== "");
  }
  return (
    <>
      <Modal size="lg" show={show} onHide={hideModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Addons Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Select Addons Type"
              value={addonsType}
              searchable={false}
              options={[
                { value: "extension", label: "Extension" },
                { value: "addons", label: "Addons" },
              ]}
              handleChange={(e) => {
                setAddonsType(e);
                if (e.value === "addons") {
                  setSelected(null);
                } else {
                  setAddons({
                    marketplace_items_limit: {
                      quantity: "",
                      whole_price: 0,
                    },
                    emails_limit: {
                      quantity: "",
                      whole_price: 0,
                    },
                    sms_limit: {
                      quantity: "",
                      whole_price: 0,
                    },
                  });
                }
              }}
            />
          </div>
          <hr />
          {addonsType.value === "extension" ? (
            <Extension ref={extensionRef} data={data} clicked={clicked} setClicked={setClicked} selected={selected} setSelected={setSelected} close={hideModal} toggle={toggle} />
          ) : (
            <ConsumableAddons ref={consumableRef} data={data} close={hideModal} toggle={toggle} addons={addons} setAddons={setAddons} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end mt-4">
            <Button disabled={addonsType.value === "extension" ? !selected : !validateLimits(addons)} onClick={handleClick}>
              {!clicked && addonsType.value === "extension" ? "Continue" : "Submit"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddonsModal;
