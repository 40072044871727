import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useApi from "../../Hooks/useApi";
import { Col, Form, Row } from "react-bootstrap";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { toast } from "react-toastify";

const consumableAddons = forwardRef(({ toggle, close, addons, setAddons, data }, ref) => {
  const [isFree, setIsFree] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [item, setItem] = useState("");
  const fetchAllConsumableItemsCall = useApi(api.fetchAllConsumableItems);
  const addonsRequests = useApi(postapi.addonsRequest, { hasCatchError: true });

  const fetchConsumableItems = async () => {
    try {
      const res = await fetchAllConsumableItemsCall.request();
      const data = res?.data?.data;
      if (data) {
        const convertedObject = data?.consumableItems?.reduce((acc, item) => {
          acc[item.name] = +item.per_unit_price;
          return acc;
        }, {});
        setItem(convertedObject);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchConsumableItems();
  }, []);

  function validateLimits(limits) {
    return Object.values(limits).some((limit) => limit.quantity !== "");
  }

  const handleChangeCount = (e) => {
    if (Number(e.target.value) < 0) {
      setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: "", whole_price: 0 } }));
    } else {
      if (e.target.value === "" || e.target.value === "0") {
        setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: "", whole_price: 0 } }));
      } else {
        if (isFree) {
          setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: e.target.value, whole_price: 0 } }));
        } else {
          setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: e.target.value, whole_price: Number(item[e.target.name] * e.target.value).toFixed(2) } }));
        }
      }
    }
  };
  useImperativeHandle(ref, () => ({
    AddRequest: async (e) => {
      e.preventDefault();

      if (data.id && validateLimits(addons)) {
        try {
          const requestedItems = [];
          for (const [key, value] of Object.entries(addons)) {
            if (value?.quantity !== "") requestedItems.push({ name: key, quantity: value.quantity, whole_price: value.whole_price });
          }
          let finalObject = {
            shop_id: data?.id,
            requested_items: requestedItems,
            is_paid: !isFree,
          };
          const res = await addonsRequests.request(finalObject);
          if (res) {
            toast.success("Request Added Successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            toggle((prev) => !prev);
            close();
          }
          if (addonsRequests.error) {
            setError(addonsRequests.error.data.error.message);
          }
        } catch (err) {
          setError(err.response.data.error.message);
        }
      }
    },
  }));

  const handleChangeIsFree = (e) => {
    setIsFree(e.target.checked);
    setAddons((prev) => {
      const temp = {};
      for (let key in prev) {
        if (e.target.checked) {
          temp[key] = { quantity: prev[key].quantity, whole_price: 0 };
        } else {
          temp[key] = { quantity: prev[key].quantity, whole_price: Number(item[key] * prev[key].quantity).toFixed(2) };
        }
      }
      return temp;
    });
  };
  return (
    <>
      <Form noValidate validated={validated}>
        {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

        <Row className="">
          <Col md={12} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Shop<span className="text-danger">*</span>
              </label>
              <Form.Control placeholder="Select shop" name="shop" value={data.shop_name + " [" + data.id + "]"} disabled={true} />
              <Form.Control.Feedback type="invalid">* Please Select Shop</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Marketplace Listings</label>
              <Form.Control
                value={addons.marketplace_items_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="marketplace_items_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                min={1}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Marketplace Litings Amount</label>
              <Form.Control value={addons.marketplace_items_limit.whole_price} name="marketplace_items_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Emails Limit</label>
              <Form.Control
                value={addons.emails_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="emails_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Emails Amount</label>
              <Form.Control value={addons.emails_limit.whole_price} name="emails_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col className="py-2" md={6}>
            <Form.Group className="mb-3" controlId="price">
              <label>SMS Limits</label>
              <Form.Control
                value={addons.sms_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="sms_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>SMS Amount</label>
              <Form.Control value={addons.sms_limit.whole_price} name="sms_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Group className="d-flex gap-3">
              <Form.Check value={isFree} onChange={handleChangeIsFree} />
              <Form.Label>As Gift</Form.Label>
            </Form.Group>
          </Col>
          <div className="d-flex ">
            <strong>Amount Payable: </strong>
            <p className="ms-1">
              {" "}
              {Math.floor(Number(addons.marketplace_items_limit.whole_price) + Number(addons.emails_limit.whole_price) + Number(addons.sms_limit.whole_price)) > 0
                ? Math.floor(Number(addons.marketplace_items_limit.whole_price) + Number(addons.emails_limit.whole_price) + Number(addons.sms_limit.whole_price))
                : 0}
            </p>
          </div>
        </Row>
      </Form>
    </>
  );
});

export default consumableAddons;
