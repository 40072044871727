import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask'

const PhoneInput = ({placeholder,value, onChange, required}) => {
    return ( 
        <InputMask 
            mask="+\92-399-9999999" 
            value={value} 
            onChange={onChange}
        >
            {(inputProps) => <Form.Control {...inputProps} placeholder={placeholder} type="tel" disableUnderline required={required} />}
        </InputMask>
     );
}
 
export default PhoneInput;