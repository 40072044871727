import { useState } from "react";
import Topbar from "../Components/Navbar";
import style from "./../styles/Sidebar.module.css";
import { Outlet } from "react-router-dom";
import Sidebar from "../Components/Sidebar/Sidebar";
import { ToastContainer } from "react-toastify";

const DashboardLayout = () => {
  const [isSandwichOpen, setIsSandwichOpen] = useState(false);
  const toggle = () => {
    setIsSandwichOpen((prev) => !prev);
  };
  return (
    <div className={`d-flex flex-column ${style.dashboardLayout}`}>
      <Topbar toggle={toggle} isSandwichOpen={isSandwichOpen} />
      <div style={{ zIndex: 2 }} className={`fixed-top ${isSandwichOpen ? style.sidebarLayoutClose : style.sidebarLayout}`}>
        <Sidebar setIsSandwichOpen={setIsSandwichOpen} isSandwichOpen={isSandwichOpen} toggle={toggle} />
      </div>
      <div className={`mt-5 ${style.content} ${isSandwichOpen ? style.contentClose : ""}`}>
        <div className="">
          <Outlet />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DashboardLayout;
