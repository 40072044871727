import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import style from "../../styles/Notification.module.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateTime, requestDateTime } from "../../Features/dateTime";
import readNotificationIcon from "../../assets/svg/readNotificationIcon.svg";
import unreadNotificationIcon from "../../assets/svg/unreadNotificationIcon.svg";
import filledCircle from "../../assets/svg/filledCircle.svg";
import search from "../../assets/svg/search.svg";
import { useDispatch } from "react-redux";
import NotificationImageModal from "./NotificationImageModal";
import customloader from "../../assets/images/RollingLoader.gif";
const NotificationDropdown = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [data, setData] = useState(null);
  const handleClick = () => {
    setIsClicked(true);
    handleClose();
    navigate("/notifications");
    setIsClicked(false);
  };
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + `/notifications/?user_id=${user.user_id}`, {
        withCredentials: true,
      })
      .then((data) => {
        setNotifications(data.data.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setNotifications([]);
        }
      });
  }, [show === true]);

  const handleNotificationStatus = async (item) => {
    try {
      let itemPayload;
      if (typeof item?.payload === "string") {
        itemPayload = JSON.parse(item?.payload);
      } else {
        itemPayload = item?.payload;
      }
      if (item.type === "5" && item.is_read === 0) {
        const response = await axios.put(
          process.env.REACT_APP_API_URL + `/notifications/mark-as-read`,
          { groupTag: item.group_tag },
          {
            withCredentials: true,
          }
        );
        if (response) {
          if (itemPayload !== null && itemPayload?.image !== null) {
            setImageModal(true);
            setData({ image: itemPayload?.image, link: item?.action });
          } else {
            if (item.action !== null) {
              const link = document.createElement("a");
              link.target = "_blank";
              link.href = item.action;
              link.rel = "noopener noreferrer";
              link.click();
            } else {
              handleClose();
              navigate("/notifications");
            }
          }
        }
      } else if (item.type === "5" && item.is_read === 1) {
        if (itemPayload !== null && itemPayload?.image !== null) {
          setImageModal(true);
          setData({ image: itemPayload?.image, link: item?.action });
        } else if (item.action !== null) {
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = item.action;
          link.rel = "noopener noreferrer";
          link.click();
        } else {
          handleClose();
          navigate("/notifications");
        }
      } else {
        handleClose();
        navigate("/notifications");
      }
    } catch (err) {
      const tempErr = err.response.data.error;
      if (tempErr.status === 404 && tempErr.message === "No Record Found") {
        setNotifications([]);
      }
    }
  };
  const datetimeFunction = (value) => {
    const dateFormat = dateTime(value);
    return dateFormat.toDateString() + "  at " + dateFormat.toLocaleTimeString();
  };

  const listOfClasses = useMemo(() => ["notificationButton", "notification-image-modal-link-button", "notification-image-modal-img", "btn-close", "modal-title", "notification-image-modal-img-container", "modal-header", "modal-footer"], []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (!listOfClasses.some((c) => event.target.classList.contains(c))) {
          handleClose();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Dropdown show={show} align="end" ref={dropdownRef}>
        <Dropdown.Menu className={`border-0 shadow mt-1 p-3 ${style.DropdownMenu}`}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : notifications.length === 0 ? (
            <div className="text-center p-3">
              <img height="45px" width="45px" src={search} alt="search" className="mb-3" />

              <h5>You don't have any notification yet!</h5>
            </div>
          ) : (
            notifications?.map((item, index) => (
              <>
                <Dropdown.Item
                  className={`${style.DropdownItem} mt-2`}
                  style={item.is_read === 0 ? { backgroundColor: "#F8F6FF", borderRadius: "14px" } : null}
                  id={index}
                  onClick={() => {
                    handleNotificationStatus(item);
                  }}
                >
                  <div className="d-flex gap-4 p-1 cursor-pointer">
                    <div className="d-flex align-items-center">
                      <img
                        style={{
                          pointerEvents: "none",
                        }}
                        width="49px"
                        height="49px"
                        src={item?.is_read === 0 ? unreadNotificationIcon : readNotificationIcon}
                        alt="notification Icon"
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center fs-6">
                      <strong className="text-truncate" style={{ maxWidth: "300px" }}>
                        <div dangerouslySetInnerHTML={{ __html: item?.title }}></div>
                      </strong>
                      <small className="text-truncate" style={{ maxWidth: "300px" }}>
                        {item?.body}
                      </small>
                      <small style={{ color: "#898995" }}>{datetimeFunction(item?.created_at)}</small>
                    </div>
                    {item?.is_read === 0 && (
                      <div className="d-flex align-items-center justify-content-end" style={{ marginLeft: "auto" }}>
                        <img width="6px" height="6px" src={item?.is_read === 0 ? filledCircle : null} alt="Unread Notification Icon" />
                      </div>
                    )}
                  </div>
                </Dropdown.Item>
                {item?.is_read === 0 ? "" : <Dropdown.Divider />}
              </>
            ))
          )}
          {notifications?.length > 0 && (
            <>
              <div className={`d-flex justify-content-center ${style.DropdownMenuFooter} mt-3`}>
                <Dropdown.Item
                  style={{
                    color: "#9537ff",
                    backgroundColor: isClicked ? "#d9d9e9" : null,
                  }}
                  onClick={() => {
                    handleClick();
                  }}
                >
                  See All &gt;
                </Dropdown.Item>
              </div>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <NotificationImageModal
        show={imageModal}
        close={() => {
          setImageModal(false);
          setData(null);
        }}
        data={data}
      />
    </>
  );
};

export default NotificationDropdown;
