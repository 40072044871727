import { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ResendEmail = ({shopId, close}) => {
    const [email, setEmail] = useState("");
    const [validated, setValidated] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [isClicked, setIsClicked] = useState(false);

    useEffect(()=> {
        axios.get(process.env.REACT_APP_API_URL + `/shops/${shopId}`, {withCredentials:true}).then((res)=> {
            setEmail(res.data[0].owner_email);
        }).catch((err)=> {
            throw new Error(err);
        })
    }, [shopId]);

    const handleSubmit = async (e)=> {
        try {
            setError("");
            setIsClicked(true);
            e.preventDefault();
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.stopPropagation();
            }
            setValidated(true);
            const res = await axios.post(process.env.REACT_APP_API_URL + `/shops/register-shop-email/${shopId}`, {email}, {withCredentials: true});
            setIsClicked(false);
            setSuccess("Email has been sent successfully.");
            setTimeout(()=>close(), 1500);
        } catch (error) {
            setIsClicked(true);
            setSuccess("");
            setError(error.response.data.error.message);
            setIsClicked(false);
        }
    }

    return ( 
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {error && <p className="text-danger p-2 text-center mb-2 mt-0 rounded-1">* {error}</p>}
            {success && <p className="text-success p-2 text-center mb-2 mt-0 rounded-1">{success}</p>}
            <Form.Group className="mb-3">
                <Form.Label>Shop Owner Email</Form.Label>
                <Form.Control className="p-2" type="email" placeholder='Enter Email' value={email} onChange={(e)=> setEmail(e.target.value)} required />
            </Form.Group>
            <div className='mt-4'>
                <Button type="submit" disabled={isClicked} className='w-100'>Send Email</Button>
            </div>
        </Form>
     );
}
 
export default ResendEmail;