import axios from "axios";

// Login User

const login = async (userData)=> {
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + '/auth/login', userData, { withCredentials: true});
        localStorage.setItem('refreshToken',res.data.refreshToken)
        return res.data;
    } catch (error) {
        throw new Error(error.response?.data.error.message);
    }
}

const getUser = async (refreshToken)=> {
    try {
        const res = await axios.get(process.env.REACT_APP_API_URL + '/auth/get-user-by-accesstoken', { withCredentials: true, headers:{
            "Authorization": refreshToken
        } });
        return res.data;
    } catch (error) {
        throw new Error(error.response?.data.error.message);
    }
}

const authService = {
    login,
    getUser
}

export default authService;