import style from "./../../styles/Sidebar.module.css";
import profile from "./../../assets/images/profile.png";
import SideNavData from "./SideNavData";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Menu from "./Menu";

const Sidebar = ({ setIsSandwichOpen, isSandwichOpen, toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const userName1 = user.user_full_name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'');

  return (
    <div
      className={`bg-white ${style.sidebar} ${
        !isSandwichOpen ? "" : style.sandwichOpen
      }`}
    >
      <div
        className={`${
          isSandwichOpen ? "justify-content-xl-center" : ""
        } py-3 px-2 ${style.infoDiv}`}
      >
        <div className={`${style.userInfo}`}>
          <div className="ms-2 me-2">
            <div className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>
                {userName1}
            </div>
          </div>
          {!isSandwichOpen && (
            <div>
              <p className="fw-bold mb-0 text-capitalize">
                {user && user.user_full_name}
              </p>
              <span className="fw-light text-capitalize">
                {user && user.user_type}
              </span>
            </div>
          )}
        </div>
        <div
          role="button"
          className="text-white d-md-block d-lg-none"
          onClick={() => toggle()}
        >
          Close <FontAwesomeIcon icon={faClose} />
        </div>
      </div>
      <div
        className={`pt-4 d-flex flex-column justify-content-between ${
          isSandwichOpen ? "align-items-center" : "align-items-start"
        } ${style.sidebarItems}`}
      >
        <div className={`${style.scroll} ${style.scrollheight} w-100`}>
          <Menu
            setIsSandwichOpen={setIsSandwichOpen}
            navData={SideNavData}
            isSandwichOpen={isSandwichOpen}
          />
        </div>
       
      </div>
    </div>
  );
};

export default Sidebar;
