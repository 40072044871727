import { useRef, useState } from "react";
import { Form, Row, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import style from "./../../../styles/ResetPassword.module.css";
import * as postapi from "./../../../api/postapi";
import * as api from "./../../../api/getapi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import customloader from "./../../../assets/images/RollingLoader.gif";
import useApi from "./../../../Hooks/useApi";
import { useEffect } from "react";
import ConfirmationModal from "./ConfirmationModal";

const Transaction = ({ id, userId, data, close, toggle, nextPlan }) => {
  const [plan, setPlan] = useState([]);
  const [planId, setPlanId] = useState();
  const { user } = useSelector((state) => state.auth);
  const discountTarget = useRef(null);

  const [isClicked, setIsClicked] = useState(false);
  const imageUpload = useApi(postapi.UPloadImage);
  const addreq = useApi(postapi.SubmitTransaction);
  const PayForNextPlan = useApi(postapi.PayForNextPlan);
  const getPlan = useApi(api.getPlanByShopId);
  const [planAmount, setPlanAmount] = useState();
  const [paidAmount, setPaidAmount] = useState();
  const [transDate, setTransDate] = useState(new Date().toISOString().split("T")[0]);
  const [billingPeriod, setBillingPeriod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectImage, setSelectImag] = useState();
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectImageURL, setSelectImagURL] = useState();
  const [remaining, setRemaining] = useState();
  const [discount, setDiscount] = useState(0);
  const [payable, setPayable] = useState();
  const [transactionId, settransactionId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [arrears, setArrears] = useState(0);
  const [Dshow, setDShow] = useState(false);

  useEffect(() => {
    const fetchReq = async () => {
      try {
        setIsLoading(true);
        const res = await getPlan.request(id);
        setPlanId(res.data?.plan?.plan_id);
        setPlan(res.data?.plan?.plan_name);
        setBillingPeriod(res.data?.plan?.current_plan_billing_frequency);
        setPlanAmount(res.data?.plan?.total);
        setArrears(res.data?.arrears?.arrears ? res.data?.arrears?.arrears : 0);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw new Error(error);
      }
    };
    fetchReq();
  }, [id]);
  useEffect(() => {
    if (arrears || discount) {
      setPayable(Number(arrears) + Number(planAmount) - Number(discount));
    } else if (arrears) {
      setPayable(Number(arrears) + Number(planAmount));
    } else {
      setPayable(planAmount);
    }
  }, [arrears, planAmount, plan, discount, payable, billingPeriod, paidAmount]);
  useEffect(() => {
    setRemaining(Number(payable) - Number(paidAmount));
  }, [paidAmount, payable]);
  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (paymentType === "cash") {
      setTransDate();
      setSelectImag();
      setSelectImagURL();
      settransactionId();
    }

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    if (plan && planAmount && paidAmount && paymentType && (paymentType === "transfer" ? transactionId && transDate && selectImage : true)) {
      if (paidAmount > payable) {
        setPaidCheck(true);
      } else {
        setShowConfirmationModal(true);
      }
    }
  }

  async function submit(e) {
    setShowConfirmationModal(true);
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    if (paymentType === "cash") {
      setTransDate();
      setSelectImag();
      setSelectImagURL();
      settransactionId();
    }
    try {
      let imgData;
      setValidated(true);
      if (selectImage) {
        imgData = await imageUpload.request(selectImage);
      }

      const [date, time] = new Date().toISOString().split("T");
      const curDate = date.split("-");
      const curTime = time.split(".")[0].replaceAll(":", "");
      const dateB32 = Number(curDate[2] + curDate[1] + curDate[0])
        .toString(32)
        .toUpperCase();
      const timeB32 = Number(curTime).toString(32).toUpperCase();
      const dateNow = new Date().toISOString().split("T")[0];
      const timeNow = new Date().toLocaleTimeString().split(" ")[0];
      const currentDate = dateNow + " " + timeNow;
      if (plan && planAmount && paidAmount) {
        setIsClicked(true);
        const obj = {
          shop_id: id,
          transaction_date: transDate ? transDate : currentDate,
          plan_id: planId,
          plan_amount: planAmount,
          arrears: arrears ? arrears : 0,
          discount: discount ? discount : 0,
          total_payable: payable,
          amount_paid: paidAmount,
          remaining_amount: remaining,
          transaction_image_url: imgData ? imgData.data.files[0] : null,
          transaction_logged_by: user.user_id,
          transaction_id: transactionId ? transactionId : id + user.user_id + dateB32 + timeB32,
        };
        let res;
        if (nextPlan === true) {
          res = await PayForNextPlan.request(obj);
        } else {
          res = await addreq.request(obj);
        }
        toast.success("Transaction Logged & Email sent Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        close();
        setIsClicked(false);
        setValidated(false);
        close();
        setShowConfirmationModal(false);
        toggle((prev) => !prev);
      } else {
        setIsClicked(false);
      }
    } catch (error) {
      // setError(error.response.data.error.message);
      setIsClicked(false);
    }
  }
  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  Plan <span className="text-danger">*</span>
                </label>

                <Form.Control className="p-2 text-capitalize" name="province" placeholder="Plan" type="text" value={plan} onChange={() => {}} required disabled />
                <Form.Control.Feedback type="invalid">* Please Select plan</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  Billing Period <span className="text-danger">*</span>
                </label>
                <Form.Control className="p-2 text-capitalize" name="province" type="text" value={billingPeriod} disabled onChange={() => {}} required />
                <Form.Control.Feedback type="invalid">* Please Select plan</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Plan Amounts <span className="text-danger">*</span>
                </label>
                <Form.Control value={Math.round(planAmount)} type="number" placeholder="" disabled />
                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Arears <span className="text-danger">*</span>
                </label>
                <Form.Control value={arrears ? arrears : 0} type="number" placeholder="Rs.1000" disabled />
                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>Discount</label>
                <Overlay target={discountTarget.current} show={Dshow} placement="right">
                  {(props) => (
                    <Tooltip style={{ background: "#ffecb5" }} id="overlay-example" {...props}>
                      Discount must be less than payable
                    </Tooltip>
                  )}
                </Overlay>
                <Form.Control
                  href="overlay-example"
                  value={discount}
                  ref={discountTarget}
                  name="discount"
                  onChange={(e) => {
                    setDiscount(e.target.value.replace(/\D/g, ""));
                    if (Number(planAmount) + Number(arrears) >= Number(e.target.value)) {
                      setDiscount(e.target.value.replace(/\D/g, ""));
                    } else {
                      setDiscount("");
                      setDShow(true);
                      setTimeout(() => {
                        setDShow(false);
                      }, 2000);
                    }
                  }}
                  type="number"
                  placeholder="Rs.1000"
                />
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Payable <span className="text-danger">*</span>
                </label>
                <Form.Control value={Math.round(payable)} type="number" placeholder="Rs.1000" disabled />
                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>Payment Type</label>
                <Form.Select
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                  required
                >
                  <option value="" selected disabled>
                    Select Payment Type
                  </option>
                  <option value="cash">Cash</option>
                  <option value="transfer">Transfer</option>
                </Form.Select>

                <Form.Control.Feedback type="invalid">Please Select Payment Type</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Paid Amount <span className="text-danger">*</span>
                </label>
                <Form.Control
                  value={paidAmount}
                  placeholder="Rs.1000"
                  required
                  max={Number(payable)}
                  onChange={(e) => {
                    if (Number(payable) >= Number(e.target.value)) {
                      setPaidCheck(false);
                      setPaidAmount(e.target.value);
                    } else {
                      setShow(true);
                      setTimeout(() => {
                        setShow(false);
                      }, 2000);
                      setPaidCheck(true);
                    }
                  }}
                />

                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              {paymentType === "transfer" && (
                <>
                  <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Id <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={transactionId}
                      name="contact_person_name"
                      onChange={(e) => {
                        settransactionId(e.target.value);
                      }}
                      placeholder="eg.AA11"
                      required={paymentType === "transfer" ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">Please Enter Transaction Id.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Date<span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={transDate}
                      onChange={(e) => {
                        setTransDate(e.target.value);
                      }}
                      disabled={true}
                      required={paymentType === "transfer" ? true : false}
                      type="date"
                    />

                    <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label className="mb-1">
                      Upload Image<span className="text-danger">*</span>
                    </label>
                    <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} required={paymentType === "transfer" ? true : false} />
                    <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              <Col className="mt-2 mb-4" lg="12" md="6" sm="12"></Col>
            </Row>
            <div className={`mb-4 ${style.fullWidth}`}>
              <Button className="w-100" type="submit">
                {"Log Transaction"}
              </Button>
              {paidCheck && (
                <div class="alert alert-danger  text-center w-100 mt-2" role="alert">
                  Paid amount must be less than payable
                </div>
              )}
            </div>
          </Form>

          <ConfirmationModal
            submit={submit}
            isClicked={isClicked}
            planType={plan}
            image={selectImageURL}
            planAmount={planAmount}
            arrears={arrears}
            remaining={remaining}
            payable={payable}
            discount={discount}
            transactionId={transactionId}
            paidAmount={paidAmount}
            transDate={transDate}
            billingPeriod={billingPeriod}
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        </div>
      )}
    </>
  );
};

export default Transaction;
