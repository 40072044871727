import { useState } from "react";
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "./../../Components/SearchableSelect";
import ShopkeeperOnPayment from "./ShopkeeperData/ShopKeeperOnPayment";
import ShopKeeperOnTrial from "./ShopkeeperData/ShopKeeperOnTrial";
import { useSelector } from "react-redux";
import style from './../../styles/Shopkeepers.module.css';
import SearchMobile from "../../Components/SearchMobile";

const Shopkeepers = () => {
  const loc = useLocation();
  const tab = loc.state;
  const [searchInput, setSearchInput] = useState("");
  const { user } = useSelector((state) => state.auth);
  const salesman = user.user_id;
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [activeTab, setActiveTab] = useState(tab ? tab : "Payment");

  const [status, setStatus] = useState({ value: true, label: "Active" });
  // shops on  payment

  const [orderBy, setOrderBy] = useState({ shop_name: "ASC" });
  // Search Products
  const searchFilter = (e) => {
    // e.preventDefault();
    // if (e.target.value === "") {
    //   setSearchInput("");
    // } else {
      setSearchInput(e.target.value);
    // }
  };

  const resetFilterHandle = () => {
    setSearchInput("");
    setStatus({ value: true, label: "Active" });
  };

  const handleTabs = (e) => {
    setActiveTab(e);
    // resetFilterHandle();
    setSearchInput("")
  };
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearchInput("");
  };
  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };

  const searchResult = () => {
    setToogleMobileSearch(false);
  };

  const filter = () => {
    setToogleMobileSearch(false);
  };

  return (
    <>
      {toogleMobileSearch === "search" ? (
        <SearchMobile
          toggleSearch={handleAcoordianCLose}
          value={searchInput}
          handleChange={searchFilter}
          searchResult={searchResult}
          searchTitle={"shop_name"}
        />
      ) : (
        <Container fluid>
          <Row className={`align-items-center ${style.flexGap}`}>
            <Col xs="6" sm="6" md={4} lg="3" className="d-inline flexGap">
              <SearchInput
                searchlogo
                handleChange={searchFilter}
                value={searchInput}
              />
            </Col>
            <Col lg="3" className="d-none d-lg-inline"></Col>
            <Col xs="6" sm="6" md={4} lg="2" className="d-inline flexGap">
              <SearchableSelect
                selectClassName={"bg-white"}
                placeholder="Filter Status"
                defaultValue={{ value: true, label: "Active" }}
                value={status}
                searchable={false}
                options={[
                  { value: true, label: "Active" },
                  { value: false, label: "Inactive" },
                  { value: "awaiting", label: "Awaiting Activation" }
                ]}
                handleChange={(e) => {
                  setStatus(e);
                }}
              />
            </Col>

            {/* For Mobile screen Start */}
            <Col xs="6" sm="6" md={4} lg="2" className="flexGap">
              <SearchableSelect
                selectClassName={"bg-white"}
                placeholder={`Showing ${sizeOfPages}`}
                defaultValue={`Showing ${sizeOfPages}`}
                value={`Showing ${sizeOfPages}`}
                searchable={false}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "30", label: "30" },
                ]}
                handleChange={handlePageSize}
              />
            </Col>
            <Col xs="6" sm="3" lg="2" className="d-lg-inline d-md-none d-sm-inline">
              <Link to="/register-shop">
                <Button>
                  Register Shop
                </Button>
              </Link>
            </Col>

            {/* <Col xs="2" lg="3" className="d-md-none d-inline " onClick={() => handleAcoordianOpen("search")}>
              <SearchInput searchlogo bordered />
            </Col>
            <Col className="d-md-none d-flex p-0" xs="2" lg="2">
              <Button variant="outline-primary">
                <FontAwesomeIcon icon={faFilter} />
              </Button>
            </Col> */}
            {/* For Mobile screen End */}
          </Row>

          <>
            <Row className="pt-3">
              <Col xs="5" md="3" lg="2">
                <Button
                  size="sm"
                  type="submit"
                  style={{ fontSize: "14px" }}
                  onClick={resetFilterHandle}
                >
                  <span>Reset Filters</span>{" "}
                </Button>
              </Col>
              <Col xs="7" md="3" lg="2" className="d-lg-none d-md-inline d-none">
                <Link to="/register-shop">
                  <Button>
                    Register Shop
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <Col>
                <Tabs defaultActiveKey={activeTab ? activeTab : "Payment"} className={`${style.navTabs}`} onSelect={handleTabs}>
                  <Tab
                    eventKey="Payment"
                    title="On Payment"
                    className="text-secondary mb-5"
                  >
                    <ShopkeeperOnPayment
                      salesman={salesman}
                      search={searchInput}
                      status={status}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      sizeOfPage={sizeOfPages}
                      activeTab={activeTab}
                    />
                  </Tab>

                  <Tab
                    eventKey="onTrial"
                    title={`On Trial`}
                    className="text-secondary mb-5"
                  >
                    <ShopKeeperOnTrial
                      salesman={salesman}
                      search={searchInput}
                      status={status}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      sizeOfPage={sizeOfPages}
                      activeTab={activeTab}
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </>
        </Container>
      )}
    </>
  );
};
export default Shopkeepers;
