import React, { useEffect, useState } from "react";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { Button, Col, Container, Row } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import NoResult from "../../Components/NoResult/NoResult";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import PaginationBar from "../../Components/PaginationBar/PaginationBar";
import CustomizeTableview from "../../Components/CustomizedTable/CustomizedTable";
import customloader from "../../assets/images/RollingLoader.gif";
import moment from "moment/moment";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { useSelector } from "react-redux";

const SellerRegistrationRequests = () => {
  const { user } = useSelector((state) => state.auth);
  let [registrationRequests, setRegistrationRequests] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "ASC" });
  const [searchInput, setSearchInput] = useState("");
  const [city, setCity] = useState(null);
  const [citiesOption, setCitiesOption] = useState([]);
  const [fromDate, setFromDate] = useState(
    moment().subtract(29, "days").startOf("day")._d
  );
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [status, setStatus] = useState(null);
  const RequestHandleCall = useApi(postapi.RequestUpdate);
  const fetchAllSellerRegistrationRequestsCall = useApi(
    api.fetchAllSellerRegistrationRequests
  );
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const handleStatus = (e) => {
    if (e.label === "none") {
      setStatus({});
    } else {
      setStatus({ value: e.value, label: e.label });
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setCity(null);
    setStatus(null);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const getCities = async () => {
    try {
      const data = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/cities",
        {
          country: "Pakistan",
        }
      );
      setCitiesOption(
        data.data.data.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    } catch (error) {}
  };
  useEffect(() => {
    getCities();
  }, []);
  const fetchSellerRegistrationRequests = async () => {
    const res = await fetchAllSellerRegistrationRequestsCall.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      user_id: user.user_id,
      filters: {
        orderBy: orderBy,
        dates: { from: fromDate, to: toDate },
        city: city?.label,
        status: status?.label,
      },
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setRegistrationRequests(data?.data?.requests);
      setCount(data?.data?.pagination?.no_of_items);
      if (data?.data?.pagination?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.data?.pagination?.no_of_pages);
        if (currentPage > data?.data?.pagination?.no_of_pages) {
          setCurrentPage(data?.data?.pagination?.no_of_pages);
        }
      }
    } else {
      setRegistrationRequests([]);
    }
  };
  useEffect(() => {
    fetchSellerRegistrationRequests();
  }, [
    currentPage,
    refresh,
    sizeOfPages,
    searchInput,
    fromDate,
    toDate,
    toggle,
    city,
    orderBy,
    status,
  ]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const filterCities = (e) => {
    if (e.label === "none") {
      setCity({});
    } else {
      setCity({ value: e.value, label: e.label });
    }
  };
  const converted = (data) => {
    requestHandle(data["id"], "Converted");
  };
  const UnConverted = (data) => {
    requestHandle(data["id"], "UnConverted");
  };
  const requestHandle = async (id, status) => {
    try {
      await RequestHandleCall.request(id, status);
      refresh((pre) => !pre);
    } catch {}
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput
              searchlogo
              handleChange={searchFilter}
              value={searchInput}
            />
          </Col>
          <Col xs="2" lg="2" xl="2" className="d-none d-lg-inline mb-3"></Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: "1", label: "Assigned" },
                { value: "2", label: "Converted" },
                { value: "3", label: "UnConverted" },
              ]}
              handleChange={handleStatus}
            />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchableSelect
              placeholder="Filter City"
              value={city}
              options={citiesOption}
              handleChange={filterCities}
            />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <DatePicker
              important={true}
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button
              className="mt-3"
              size="sm"
              type="submit"
              style={{ fontSize: "14px" }}
              onClick={resetFilterHandle}
            >
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "70vh" }}
            >
              <img
                src={customloader}
                alt="customloader"
                width="100"
                height="100"
              />
            </div>
          ) : !registrationRequests[0] ? (
            <NoResult />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview
                data={registrationRequests}
                columnNotShow={[
                  "id",
                  "request_handled_by",
                  "deleted_at",
                  "status",
                ]}
                dateColumn={["created_at", "updated_at"]}
                sortColumn={[
                  "created_at",
                  "updated_at",
                  "shop_name",
                  "first_name",
                  "city",
                ]}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                status={{
                  column: "status",
                  getClassName: (value) => {
                    if (value["status"] === "Assigned") {
                      return "text-warning";
                    } else if (value["status"] === "Converted") {
                      return "text-success";
                    } else {
                      return "text-danger";
                    }
                  },
                  format: (value) => {
                    if (value["status"] === "Assigned") {
                      return "Assigned";
                    } else if (value["status"] === "Converted") {
                      return "Converted";
                    } else {
                      return "UnConverted";
                    }
                  },
                }}
                converted={converted}
                UnConverted={UnConverted}
              />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar
                    noOfPages={noOfPages}
                    currentPage={currentPage}
                    changePage={changePage}
                  />
                </Col>
                <Col
                  className="d-md-none d-flex align-items-center mt-3"
                  xs="6"
                  lg="3"
                >
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
        </>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default SellerRegistrationRequests;
