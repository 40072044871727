import { faRegistered, faShop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import shops from "./../../assets/svg/vendor.svg";

const SideNavData = [
  {
    title: "Register Shop",
    path: "/register-shop",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    title: "My Shopkeepers",
    path: "/shopkeepers",
    icon: <img src={shops} alt="shops" />,
  },
  {
    title: "Seller Registration",
    path: "/seller-registration",
    icon: <FontAwesomeIcon icon={faRegistered} />,
  },
];

export default SideNavData;
