import React, { useState } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";

import sortAsc from "../../assets/svg/icon.svg";
import sortDesc from "../../assets/svg/Up.svg";

import removeIcon from "../../assets/svg/remove.svg";

import editpencil from "../../assets/svg/editpencil.svg";

import ellipsis from "../../assets/svg/Ellipsis.svg";
import printIcon from "../../assets/svg/print.svg";
import emailIcon from "../../assets/svg/email.svg";
import eyeIcon from "../../assets/svg/union.svg";

import purchaseOrderIcon from "../../assets/svg/purchase_order.svg";
import paymentIcon from "../../assets/svg/paymentIcon.svg";

import archiveIcon from "../../assets/svg/archive.svg";
import unarchiveIcon from "../../assets/svg/Unarchive.svg";
import duplicateIcon from "../../assets/svg/save.svg";
import settingsIcon from "../../assets/svg/settings.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { dateTime } from "../../Features/dateTime";

const transform = (text) => {
  const temp = text.split("_");
  let ret = "";
  temp.forEach((txt) => {
    ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
  });
  return ret;
};

const CustomizedTable = ({
  data,
  settings = null,
  edit = null,
  remove = null,
  duplicate = null,
  archive = null,
  unarchive = null,
  columnNotShow = [],
  dateColumn = [],
  datetimeColumn = [],
  sortColumn = [],
  thumbnail = null,
  orderBy = null,
  setOrderBy = null,
  status = null,
  className,
  style,
  print = null,
  view = null,
  email = null,
  purchaseOrder = null,
  payment = null,
  actionButton = null,
  actions = true,
  converted,
  UnConverted,
  download = null,
  addons,
}) => {
  const [id, setId] = useState(-1);
  const [modelDeleteShow, setModalDeleteShow] = useState(false);
  const [modelArchiveShow, setModalArchiveShow] = useState(false);
  const [modelUnArchiveShow, setModalUnArchiveShow] = useState(false);

  const deleteConfirm = (id) => {
    remove(id);
    setModalDeleteShow(false);
  };

  const cancelConfirm = () => {
    setModalDeleteShow(false);
    setModalArchiveShow(false);
    setModalUnArchiveShow(false);
  };
  const archiveConfirm = (id) => {
    archive(id);
    setModalArchiveShow(false);
  };
  const unarchiveConfirm = (id) => {
    unarchive(id);
    setModalUnArchiveShow(false);
  };
  const handleSort = (column) => {
    if (orderBy) {
      const temp = new Object();
      if (Object.keys(orderBy)[0] === column) {
        if (orderBy[column] === "ASC") {
          temp[column] = "DESC";
        } else {
          temp[column] = "ASC";
        }
      } else {
        temp[column] = "ASC";
      }
      setOrderBy(temp);
    }
  };

  const tdNotShow = [...columnNotShow, ...dateColumn, ...datetimeColumn];
  if (thumbnail) {
    tdNotShow.push(thumbnail?.img, thumbnail?.description);
  }

  var statusVar;
  var actionButtonVar = actionButton?.column ? (Array.isArray(actionButton?.column) ? {} : null) : undefined;

  return (
    <>
      <Table responsive striped className={`rounded-3 bg-white border-1 border-secondary overflow-hidden m-0 mb-0 text-nowrap ${className}`}>
        <thead className="text-secondary" style={{ fontSize: "13px", fontWeight: "400", lineHeight: "18px" }}>
          <tr>
            {thumbnail && (
              <th
                style={{
                  ...(orderBy && Object.keys(orderBy)[0] === thumbnail?.description ? { color: "#9537FF", fontWeight: "bold" } : { fontWeight: "400" }),
                  ...(sortColumn.includes(thumbnail?.description) ? { cursor: "pointer" } : {}),
                }}
                onClick={() => {
                  if (sortColumn) {
                    if (sortColumn.includes(thumbnail?.description)) {
                      handleSort(thumbnail?.description);
                    }
                  }
                }}
              >
                {transform(thumbnail?.title)}
                {sortColumn && sortColumn.includes(thumbnail?.description) && <img alt="thumbnail" className="ms-1" src={orderBy[thumbnail?.description] === "ASC" ? sortDesc : sortAsc} />}
              </th>
            )}
            {Object.keys(data[0]).map((s, index) => {
              if (!columnNotShow?.includes(s)) {
                return (
                  <th
                    key={index}
                    style={{
                      ...(orderBy && Object.keys(orderBy)[0] === s ? { color: "#9537FF", fontWeight: "bold" } : { fontWeight: "400" }),
                      ...(sortColumn.includes(s) ? { cursor: "pointer" } : {}),
                    }}
                    onClick={() => {
                      if (sortColumn) {
                        if (sortColumn.includes(s)) {
                          handleSort(s);
                        }
                      }
                    }}
                  >
                    {transform(s)}
                    {sortColumn && sortColumn.includes(s) && <img alt="thumbnail" className="ms-1" src={orderBy[s] === "ASC" ? sortDesc : sortAsc} />}
                  </th>
                );
              }
            })}
            {status && <th>Status</th>}
            {actions && (
              <th className="border-start" style={{ fontWeight: "400" }}>
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr className="border-bottom" key={index}>
              {thumbnail && (
                <td className={`border-bottom-0 ${thumbnail?.className ? thumbnail?.className : ""}`}>
                  <img style={{ borderRadius: "6px" }} width="32" height="32" alt="thumbnail" src={row[thumbnail?.img]} className="me-2" />
                  {row[thumbnail?.description]}
                </td>
              )}
              {Object.entries(row).map(([k, v], i) => {
                if (!tdNotShow?.includes(k)) {
                  if (status?.column === k) {
                    statusVar = v;
                  }
                  if (actionButton?.column && Array.isArray(actionButton?.column)) {
                    if (actionButton?.column.includes(k)) {
                      actionButtonVar = { ...actionButtonVar, [k]: v };
                    }
                  } else if (actionButton?.column === k) {
                    actionButtonVar = v;
                  }
                  return (
                    <td className="border-bottom-0" key={i}>
                      {" "}
                      {v}{" "}
                    </td>
                  );
                }
                if (actionButton?.column && Array.isArray(actionButton?.column)) {
                  if (actionButton?.column.includes(k)) {
                    actionButtonVar = { ...actionButtonVar, [k]: v };
                  }
                } else if (actionButton?.column === k) {
                  actionButtonVar = v;
                }
                if (dateColumn?.includes(k)) {
                  if (v) {
                    const dateFormat = new Date(v);
                    return (
                      <td className="border-bottom-0" key={i}>
                        {dateFormat.toDateString()}
                      </td>
                    );
                  } else {
                    return <td className="border-bottom-0" key={i}></td>;
                  }
                }
                if (datetimeColumn?.includes(k)) {
                  if (v) {
                    const dateFormat = new Date(v);
                    return (
                      <td className="border-bottom-0" key={i}>
                        {dateFormat.toDateString()} {dateFormat.toLocaleTimeString()}
                      </td>
                    );
                  } else {
                    return <td className="border-bottom-0" key={i}></td>;
                  }
                }
                // return (<td className='border-bottom-0' key={i}>{v}</td>)
              })}
              {status && typeof status === "object" ? (
                <td className="">
                  <span className={`${status?.getClassName ? status?.getClassName(row) : ""}`}>{status?.format(row)}</span>
                </td>
              ) : (
                status && <td className={`border-bottom-0 ${status.style ? status.style(statusVar) : ""}`}>{status.format(statusVar)}</td>
              )}
              {actions && (
                <td className=" border-start border-bottom-0 " style={{ maxWidth: "15%", width: "100%" }}>
                  <div className="d-lg-flex align-items-center justify-content-end gap-3 px-2 d-none ">
                    {actionButton && actionButton?.isShow(actionButtonVar) && (
                      <div>
                        <Button
                          className={actionButton.className}
                          style={actionButton.style}
                          variant={actionButton.variant}
                          onClick={() => {
                            actionButton.onClick(row["id"]);
                          }}
                        >
                          {actionButton.label}
                        </Button>
                      </div>
                    )}
                    {duplicate && (
                      <div className="tooltipp">
                        <img src={duplicateIcon} alt="save" style={{ cursor: "pointer" }} onClick={() => duplicate(row.id)} />
                        <span className="tooltiptext">Duplicate</span>
                      </div>
                    )}
                    {settings && (
                      <div className="tooltipp">
                        <img src={settingsIcon} alt="edit" style={{ cursor: "pointer" }} onClick={() => settings(row["id"])} />
                        <span className="tooltiptext">Reset Password</span>
                      </div>
                    )}
                    {edit && (
                      <div className="tooltipp">
                        <img src={editpencil} alt="edit" style={{ cursor: "pointer" }} onClick={() => edit(row["id"])} />
                        <span className="tooltiptext">Edit</span>
                      </div>
                    )}
                    {archive && (
                      <div className="tooltipp">
                        <img
                          src={archiveIcon}
                          alt="archive"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalArchiveShow(true);
                            setId(row["id"]);
                          }}
                        />
                        <span className="tooltiptext">Archive</span>
                      </div>
                    )}
                    {unarchive && (
                      <div className="tooltipp">
                        <img
                          src={unarchiveIcon}
                          alt="unarchive"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalUnArchiveShow(true);
                            setId(row["id"]);
                          }}
                        />
                        <span className="tooltiptext">Unarchive</span>
                      </div>
                    )}
                    {remove && (
                      <div className="tooltipp">
                        <img
                          src={removeIcon}
                          alt="removeIcon"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalDeleteShow(true);
                            setId(row["id"]);
                          }}
                        />
                        <span className="tooltiptext">Delete</span>
                      </div>
                    )}
                    {(typeof print === "object" ? print?.isShow(row) : print) && (
                      <div style={{ cursor: "pointer" }} onClick={() => (typeof print === "object" ? print?.action(row["id"]) : print(row["id"]))} className="tooltipp">
                        <img
                          src={printIcon}
                          alt="removeIcon"
                          style={{
                            pointerEvents: "none",
                          }}
                        />
                        <span className="tooltiptext">Print</span>
                      </div>
                    )}

                    {(typeof view === "object" ? view?.isShow(row) : view) && (
                      <div style={{ cursor: "pointer" }} onClick={() => (typeof view === "object" ? view?.action(row["id"]) : view(row["id"]))} className="tooltipp">
                        <img
                          src={eyeIcon}
                          alt="removeIcon"
                          style={{
                            pointerEvents: "none",
                          }}
                        />
                        <span className="tooltiptext">View</span>
                      </div>
                    )}

                    {row["is_active"] === 2 && email && (
                      <div className="tooltipp">
                        <img src={emailIcon} alt="removeIcon" style={{ cursor: "pointer" }} onClick={() => email(row["id"])} />
                        <span className="tooltiptext">Email</span>
                      </div>
                    )}
                    {payment && (
                      <div className="tooltipp">
                        <img src={paymentIcon} alt="payment Icon" style={{ cursor: "pointer" }} onClick={() => payment(row["id"])} />
                        <span className="tooltiptext">Payments</span>
                      </div>
                    )}
                    {purchaseOrder && (
                      <div className="tooltipp">
                        <img src={purchaseOrderIcon} alt="PurchaseOrderIcon" style={{ cursor: "pointer" }} onClick={() => purchaseOrder(row["id"])} />
                        <span className="tooltiptext">Purchase Order</span>
                      </div>
                    )}
                    {converted && row.status === "Assigned" && (
                      <div>
                        <Button
                          className=" outline-primary"
                          variant="outline-primary"
                          onClick={() => {
                            converted(row);
                          }}
                        >
                          Converted
                        </Button>
                      </div>
                    )}
                    {UnConverted && row.status === "Assigned" && (
                      <div>
                        <Button
                          className=" outline-primary"
                          variant="outline-primary"
                          onClick={() => {
                            UnConverted(row);
                          }}
                        >
                          UnConverted
                        </Button>
                      </div>
                    )}
                    {(typeof download === "object" ? download?.isShow(row) : download) && (
                      <div style={{ cursor: "pointer" }} onClick={() => (typeof download === "object" ? download?.action(row["id"]) : download(row["id"]))} className="tooltipp">
                        <FontAwesomeIcon icon={faFileDownload} />
                        <span className="tooltiptext">Download</span>
                      </div>
                    )}
                    {addons && (
                      <div>
                        <Button
                          // style={!(dateTime(row["banner_showing_start_date"]) >= new Date() && dateTime(row["grace_period_ends_on"]) <= new Date()) ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }}
                          style={
                            (Number(row["is_subscription_cancelled"]) === 0 && row["cancelled_on"] !== null) || (Number(row["is_subscription_cancelled"]) === 1 && row["cancelled_on"] === null) || (Number(row["is_subscription_cancelled"]) === 1 && row["cancelled_on"] !== null)
                              ? { pointerEvents: "none", opacity: "0.5" }
                              : dateTime(row["banner_showing_start_date"]) <= new Date() && dateTime(row["grace_period_ends_on"]) >= new Date()
                              ? { cursor: "pointer" }
                              : { pointerEvents: "none", opacity: "0.5" }
                          }
                          className=" outline-primary"
                          variant="outline-primary"
                          onClick={() => {
                            addons(row);
                          }}
                        >
                          Addons
                        </Button>
                      </div>
                    )}
                  </div>
                  <Dropdown className="d-flex justify-content-center d-lg-none">
                    <Dropdown.Toggle variant="light" className="border-0 p-0" id="dropdown-basic" style={{ background: "transparent" }}>
                      <img src={ellipsis} alt="ellipsis" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className=" position-fixed rounded-0 border-0 shadow">
                      {actionButton && actionButton?.isShow(actionButtonVar) && (
                        <Dropdown.Item
                          className="purple"
                          onClick={() => {
                            actionButton.onClick(row["id"]);
                          }}
                        >
                          {actionButton.label}
                        </Dropdown.Item>
                      )}
                      {duplicate && (
                        <Dropdown.Item className="purple" onClick={() => duplicate(row.id)}>
                          Duplicate
                        </Dropdown.Item>
                      )}
                      {edit && (
                        <Dropdown.Item className="purple" onClick={() => edit(row["id"])}>
                          Edit
                        </Dropdown.Item>
                      )}
                      {unarchive && (
                        <Dropdown.Item className="purple" onClick={() => unarchive(row.id)}>
                          Unarchive
                        </Dropdown.Item>
                      )}
                      {archive && (
                        <Dropdown.Item className="purple" onClick={() => archive(row.id)}>
                          Archive
                        </Dropdown.Item>
                      )}
                      {remove && (
                        <Dropdown.Item
                          className="purple"
                          onClick={() => {
                            setModalDeleteShow(true);
                            setId(row["id"]);
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      )}

                      {(typeof print === "object" ? print?.isShow(row) : print) && (
                        <Dropdown.Item className="purple" onClick={() => (typeof print === "object" ? print?.action(row["id"]) : print(row["id"]))}>
                          Print
                        </Dropdown.Item>
                      )}
                      {(typeof view === "object" ? view?.isShow(row) : view) && (
                        <Dropdown.Item
                          className="purple"
                          onClick={() => {
                            typeof view === "object" ? view?.action(row["id"]) : view(row["id"]);
                          }}
                        >
                          View
                        </Dropdown.Item>
                      )}
                      {(typeof download === "object" ? download?.isShow(row) : download) && (
                        <Dropdown.Item className="purple" onClick={() => (typeof download === "object" ? download?.action(row["id"]) : download(row["id"]))}>
                          Download
                        </Dropdown.Item>
                      )}
                      {email && (
                        <Dropdown.Item
                          className="purple"
                          onClick={() => {
                            email(row["id"]);
                          }}
                        >
                          Email
                        </Dropdown.Item>
                      )}
                      {payment && (
                        <Dropdown.Item
                          className="purple"
                          onClick={() => {
                            payment(row["id"]);
                          }}
                        >
                          Payments
                        </Dropdown.Item>
                      )}
                      {purchaseOrder && (
                        <Dropdown.Item
                          className="purple"
                          onClick={() => {
                            purchaseOrder(row["id"]);
                          }}
                        >
                          Purchase Order
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={modelDeleteShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to delete?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100" onClick={() => deleteConfirm(id)}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Archive Model */}

      <Modal show={modelArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Archive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button>
            <Button className="w-100 rounded-3" onClick={() => archiveConfirm(id)}>
              Archive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* UnArchive Model */}

      <Modal show={modelUnArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Unarchive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Unarchive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button>
            <Button className="w-100 rounded-3" onClick={() => unarchiveConfirm(id)}>
              Unarchive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomizedTable;
