import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import useApi from "../../Hooks/useApi";
import * as postapi from "../../api/postapi";
import { toast } from "react-toastify";

const Extension = forwardRef(({ clicked, setClicked, selected, setSelected, data, toggle, close }, ref) => {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const addExtensionRequest = useApi(postapi.addExtensionRequest, { hasCatchError: true });

  // const [selected, setSelected] = useState(null);

  const handleSelect = (days) => {
    setSelected(days);
  };
  const renderTickMark = (days) => {
    if (selected === days) {
      return <div style={{ position: "absolute", top: "10px", left: "10px", fontSize: "24px", color: "#8834ff" }}>✓</div>;
    }
    return null;
  };
  const currentPlanEndDateCalculation = (planEndDate, days) => {
    const date = new Date(planEndDate);
    date.setDate(date.getDate() + days);
    return date.toISOString().split("T")[0];
  };

  useImperativeHandle(ref, () => ({
    AddRequest: async (e) => {
      try {
        e.preventDefault();
        setValidated(true);
        if (selected && data) {
          setClicked(true);
          let obj = {
            shop_id: data?.id,
            previous_end_date: data?.current_plan_end_date.split("T")[0],
            requested_duration: selected,
            current_end_date: currentPlanEndDateCalculation(data?.current_plan_end_date, selected),
            grace_period_end_date: currentPlanEndDateCalculation(data?.grace_period_ends_on, selected),
            banner_showing_date: currentPlanEndDateCalculation(data?.banner_showing_start_date, selected),
          };
          const res = await addExtensionRequest.request(obj);
          if (res) {
            toast.success("Request Added Successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            toggle((prev) => !prev);
            close();
            setError("");
          }
        }
      } catch (error) {
        setError(error.response?.data.error.message);
      }
    },
  }));

  return (
    <>
      {clicked ? (
        <>
          <Form noValidate validated={validated}>
            {error && <p className="text-danger text-center">{error}</p>}
            <Row className="">
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Shop</label>
                  <Form.Control value={data?.shop_name} name="shop_name" disabled={true} />
                </Form.Group>
              </Col>
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Plan Name</label>
                  <Form.Control value={data?.current_plan_name} name="plan_name" disabled={true} />
                </Form.Group>
              </Col>
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Plan Ends On</label>
                  <Form.Control value={new Date(data?.current_plan_end_date).toISOString().split("T")[0].split("-").reverse().join("/")} name="plan_end_date" disabled={true} />
                </Form.Group>
              </Col>

              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Requested Duration Unit</label>
                  <Form.Control value={selected >= 30 ? "Months" : "Days"} name="extension_duration_unit" disabled={true} />
                </Form.Group>
              </Col>

              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Requested Extension Days</label>
                  <Form.Control value={selected} name="extension_days" disabled={true} />
                </Form.Group>
              </Col>

              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Plan Ends On</label>
                  <Form.Control value={currentPlanEndDateCalculation(data?.current_plan_end_date, selected).split("-").reverse().join("/")} name="plan_end_date" disabled={true} />
                </Form.Group>
              </Col>
              <Col>
                {/* <Button className="w-100 mt-4" type="submit">
                  Save
                </Button> */}
              </Col>
            </Row>
          </Form>
        </>
      ) : (!clicked && data.current_plan_billing_frequency === "monthly") || data.subscription_type === "trial" ? (
        <>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 5 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(5)}>
                <Card.Body>
                  {renderTickMark(5)}
                  <Card.Title className="ps-3">For 5 days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 10 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(10)}>
                <Card.Body>
                  {renderTickMark(10)}
                  <Card.Title className="ps-3">For 10 days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 15 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(15)}>
                <Card.Body>
                  {renderTickMark(15)}
                  <Card.Title className="ps-3">For 15 days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 20 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(20)}>
                <Card.Body>
                  {renderTickMark(20)}
                  <Card.Title className="ps-3">For 20 days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <div className="d-flex justify-content-end mt-4">
            <Button
              disabled={!selected}
              onClick={() => {
                setClicked(true);
              }}
            >
              Continue
            </Button>
          </div> */}
        </>
      ) : (
        <>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 30 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(30)}>
                <Card.Body>
                  {renderTickMark(30)}
                  <Card.Title className="ps-3">For 30 Days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 60 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(60)}>
                <Card.Body>
                  {renderTickMark(60)}
                  <Card.Title className="ps-3">For 60 Days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 90 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(90)}>
                <Card.Body>
                  {renderTickMark(90)}
                  <Card.Title className="ps-3">For 90 Days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-2 cursor-pointer" style={selected === 120 ? { borderColor: "#8834ff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" } : {}} onClick={() => handleSelect(120)}>
                <Card.Body>
                  {renderTickMark(120)}
                  <Card.Title className="ps-3">For 120 days</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
});

export default Extension;
