import React from "react";
import FormCard from "../Components/FormCard";
import style from "./../styles/RegisterShop.module.css";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import { useState, useEffect } from "react";
import { default as ReactSelect } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCross } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../Components/Modal";
import Loader from "../Components/Loader";
import styleModal from "./../styles/Modal.module.css";
import ImageUploader from "../Components/ImageUploader/ImageUploader";
import { useRef } from "react";
import PhoneInput from "../Components/PhoneInput/PhoneInput";
import CnicInput from "../Components/CnicInput/CnicInput";
import { useSelector } from "react-redux";
import Map from "../Components/Map";

const RegisterShop = () => {
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const [shop_name, setShopName] = useState("");
  const [usernameError, setUserNameError] = useState();
  const [userName, setUserName] = useState("");
  const [shop_owner_name, setShopOwnerName] = useState("");
  const [shop_address, setShopAddress] = useState("");
  const [owner_CNIC, setOwnerCNIC] = useState("");
  const [owner_email, setEmail] = useState("");
  const [owner_whatsapp_number, setOwnerWhatsappNumber] = useState("");
  const [owner_backup_phone_number, setOwnerBackupPhoneNumber] = useState("");
  const [on_trial, setTrial] = useState(false);
  const [on_payment, setPayment] = useState(false);
  const [trial_till_date, setTrialTillDate] = useState("");
  const [province, setProvince] = useState(null);
  const [provinceList, setProvinceList] = useState([]);
  const [city, setCity] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [trialOrPayment, setTrialOrPayment] = useState("");
  const [plans, setPlans] = useState();
  const [error, setError] = useState("");
  const [trialPaymentError, setTrialPaymentError] = useState(false);
  const [success, setSuccess] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isClicked, setIsclicked] = useState(false);
  const [nameLoader, setNameLoader] = useState(false);
  const [daysField, setDaysField] = useState([]);
  const [selectPlan, setSelectPlan] = useState({});
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  // States for Custom Plan
  const [planAmount, setPlanAmount] = useState("");
  const [itemsLimit, setItemsLimit] = useState("");
  const [locationLimit, setLocationLimit] = useState("");
  const [emailsLimit, setEmailsLimit] = useState("");
  const [smsLimit, setSmsLimit] = useState("");
  const [yearlyTotal, setYearlyTotal] = useState("");
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [quarterlyTotal, setQuarterlyTotal] = useState("");
  const [secondaryUsersLimit, setSecondaryUsersLimit] = useState("");
  const ImageRef = useRef();
  const [image, setImage] = useState({});

  const [validated, setValidated] = useState(false);
  const [billingPeriod, setBillingPeriod] = useState({
    value: "monthly",
    label: "Monthly",
  });

  const handleRadio = (e) => {
    setTrialPaymentError(false);
    if (e.target.value === "on_trial") {
      setTrial(true);
      setTrialOrPayment("onTrial");
      setPayment(false);
    } else {
      setTrial(false);
      setPayment(true);
      setTrialOrPayment("onPayment");
    }
  };

  const daysOptions = [
    { value: "mon", label: "Mon" },
    { value: "tue", label: "Tue" },
    { value: "wed", label: "Wed" },
    { value: "thur", label: "Thu" },
    { value: "fri", label: "Fri" },
    { value: "sat", label: "Sat" },
    { value: "sun", label: "Sun" },
  ];
  const handleChange = (selected) => {
    setDaysField(selected);
  };

  useEffect(() => {
    const getprovince = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL + "/location/get-province", { withCredentials: true });
        const data = res.data.map((p) => ({
          value: p.province_id,
          label: p.province_name,
        }));
        setProvinceList(data);
      } catch (error) {
        throw new Error(error);
      }
    };
    getprovince();
  }, []);

  const handleprovince = (event) => {
    setProvince(event);
  };

  useEffect(() => {
    const getcity = (id) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/location/get-city/${id}`, {
          withCredentials: true,
        })
        .then((res) => {
          const data = res.data.map((c) => ({
            value: c.city_id,
            label: c.city_name,
          }));
          setCityList(data);
        })
        .catch((err) => {
          throw new Error(err);
        });
    };
    if (province?.value) {
      getcity(province?.value);
    }
  }, [province?.value]);

  const handlecity = (event) => {
    setCity(event);
  };

  const handleShopName = (e) => {
    setShopName(e.target.value.replace(/[^\w\s]/gi, ""));
  };

  const handleUser = (e) => {
    let username = e.target.value;
    username = username.replace(/\s+/g, "-").toLowerCase();
    username = username.replace(/\./g, "");
    username = username.replace(/\//g, "");
    setUserName(username);
    axios
      .post(process.env.REACT_APP_API_URL + `/shops/is-username-available`, { username: username }, { withCredentials: true })
      .then((res) => {
        if (res.data === false) {
          setUserNameError(true);
        } else {
          setUserNameError(false);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const handleUserName = async () => {
    try {
      setNameLoader(true);
      var username = shop_name.trim();
      username = username.replace(/\s+/g, "-").toLowerCase();
      username = username.replace(/\./g, " ");
      username = username.replace(/\//g, " ");
      let i = 1;
      let res = await axios.post(process.env.REACT_APP_API_URL + `/shops/is-username-available`, { username: username }, { withCredentials: true });
      if (res.data === false) {
        while (res.data === false) {
          res = await axios.post(process.env.REACT_APP_API_URL + `/shops/is-username-available`, { username: username }, { withCredentials: true });
          if (res.data === true) {
            setUserNameError(false);
            setUserName(username);
            setNameLoader(false);
          }
          var x = username.match(/[^\d]+|\d+/g);
          username = x[0] + (Number(i) + 1);
          i++;
        }
      } else {
        setUserNameError(false);
        setUserName(username);
        setNameLoader(false);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // Subscriptions
  useEffect(() => {
    if (on_trial === true || on_payment === true) {
      let planType;
      if (on_trial === true) {
        planType = "trial";
      } else if (on_payment === true) {
        planType = "fixed";
        axios
          .post(process.env.REACT_APP_API_URL + `/plans/get`, { planType }, { withCredentials: true })
          .then((res) => {
            const plandResData = res.data.map((data) => ({
              value: data.plan_id,
              label: data.plan_name,
            }));
            setPlans([...plandResData, { value: "custom", label: "Custom" }]);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }
    }
  }, [on_trial, on_payment]);

  useEffect(() => {
    setQuarterlyTotal(Number(planAmount) * 3 * 0.9);
    setYearlyTotal(Number(planAmount) * 12 * 0.8);
    setMonthlyTotal(Number(planAmount));
  }, [planAmount, billingPeriod]);

  const d = new Date();
  const addDate = d.setDate(d.getDate() + 10);
  const maxDate = new Date(addDate).toISOString().split("T")[0];
  async function submit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    if (on_trial === false && on_payment === false) {
      setTrialPaymentError(true);
    } else {
      setTrialPaymentError(false);
    }
    try {
      setValidated(true);

      let state;
      if (selectPlan?.value === "custom") {
        if (planAmount !== "" && itemsLimit !== "" && emailsLimit !== "" && locationLimit !== "" && smsLimit !== "" && secondaryUsersLimit !== "") {
          state = true;
        } else {
          state = false;
        }
      } else {
        state = true;
      }
      if (usernameError === false && (on_trial === true || on_payment === true) && (trial_till_date.length > 0 || (Object.values(selectPlan).length > 0 && Object.values(billingPeriod).length > 0)) && daysField.length > 0 && province?.value > 0 && city?.value > 0 && isLocationSelected && state) {
        setIsclicked(true);
        var data = "";
        if (Object.values(image).length > 0) {
          data = await ImageRef.current.uploadImage();
        }
        const shopObj = {
          registered_by: userId,
          moderator: userId,
          shop_name,
          username: userName,
          shop_owner_name,
          shop_address,
          owner_CNIC,
          owner_email,
          owner_whatsapp_number,
          owner_backup_phone_number,
          on_trial,
          on_payment,
          trial_till_date: on_trial ? trial_till_date : "1000-01-01",
          logo_path: data.length > 0 ? data : null,
        };
        const locationObj = {
          province_id: province?.value,
          city_id: city?.value,
          shop_working_days: JSON.stringify(daysField.map((day) => day.value)),
          latitude: coords?.lat,
          longitude: coords?.lng,
        };
        let plansObj;
        if (on_trial) {
          plansObj = {};
        } else if (on_payment) {
          if (selectPlan?.value === "custom") {
            plansObj = {
              plan_type: "custom",
              plan_amount: planAmount,
              marketplace_items_limit: itemsLimit,
              locations_limit: locationLimit,
              emails_limit: emailsLimit,
              sms_limit: smsLimit,
              secondary_users_limit: secondaryUsersLimit,
              monthly_grand_total: monthlyTotal,
              quarterly_grand_total: quarterlyTotal,
              yearly_grand_total: yearlyTotal,
              billing_period: billingPeriod?.value,
            };
          } else {
            plansObj = {
              plan: selectPlan?.value,
              billing_period: billingPeriod?.value,
            };
          }
        }
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/shops/register-shop",
          {
            shop: shopObj,
            location: locationObj,
            plan: plansObj,
          },
          { withCredentials: true }
        );
        setSuccess(res.data.success.message);
        setModalShow(true);
        setTimeout(() => setModalShow(false), 1500);
        setIsclicked(false);
        setValidated(false);
        if (res.data) {
          setShopName("");
          setUserName("");
          setUserNameError();
          setShopOwnerName("");
          setShopAddress("");
          setOwnerCNIC("");
          setEmail("");
          setOwnerWhatsappNumber("");
          setOwnerBackupPhoneNumber("");
          setTrial(false);
          setPayment(false);
          setTrialTillDate("");
          setCity(null);
          setProvince(null);
          setTrialOrPayment("");
          setDaysField([]);
          setImage([]);
          setError("");
          setTrialPaymentError(false);
          setSelectPlan({});
          setPlanAmount("");
          setItemsLimit("");
          setEmailsLimit("");
          setLocationLimit("");
          setSmsLimit("");
          setQuarterlyTotal("");
          setMonthlyTotal(0);
          setYearlyTotal("");
          setSecondaryUsersLimit("");
          // setCoords({lat: 0, lng: 0})
        }
      }
    } catch (error) {
      setIsclicked(true);
      setError(error?.response?.data?.error?.message);
      setIsclicked(false);
    }
  }

  return (
    <div className="w-100">
      <div className={`d-flex justify-content-center align-items-center ${style.wrapper}`}>
        <FormCard>
          <h4 className="mt-5 mb-4 text-center fw-bold">Sign Up</h4>
          {error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}
          {
            <PopUpModal
              icon={<FontAwesomeIcon icon={success ? faCircleCheck : faCross} />}
              text={success ? success : error}
              type={success ? styleModal.successBg : styleModal.errorBg}
              iconModal={success ? styleModal.successIcon : styleModal.errorIcon}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          }
          {/* {error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>} */}
          <Form noValidate validated={validated} onSubmit={submit}>
            <Row>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>
                  Shop Name <span className="text-danger">*</span>
                </label>
                <Form.Control
                  className="p-2"
                  name="shop_name"
                  type="text"
                  placeholder="Shop Name"
                  onKeyDown={(e) => ["!", "#", "$", "%", "^", "&", "*", "(", ")", ":", "'", "/", "`", "{", "}", "[", "]", "~", "+", "@", "_"].includes(e.key) && e.preventDefault()}
                  value={shop_name}
                  onChange={(e) => handleShopName(e)}
                  required
                />
                <Form.Control.Feedback type="invalid">* Please Enter Shop Name</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className={`my-2 ${style.usernameInput}`} as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>
                  Username <span className="text-danger">*</span>
                </label>
                <Form.Control
                  className={`p-2`}
                  name="shop_name"
                  type="text"
                  placeholder="Username"
                  onKeyDown={(e) => ["!", "#", "$", "%", "^", "&", "*", "(", ")", ":", "'", "/", "`", "{", "}", "[", "]", "~", "+", "@"].includes(e.key) && e.preventDefault()}
                  value={userName}
                  onChange={(e) => handleUser(e)}
                  required
                />
                <div className={`${style.generateButton}`}>
                  <Button onClick={() => handleUserName()} style={{ width: "40px", height: "40px" }} className={`${style.gButton} rounded-0 d-flex justify-content-center align-items-center`}>
                    {nameLoader === true ? <Spinner animation="border" size="sm" /> : "@"}
                    {/* <FontAwesomeIcon className="text-white" icon={faEye} /> */}
                  </Button>
                </div>
                <Form.Control.Feedback type="invalid">* Please Enter Username</Form.Control.Feedback>
                {usernameError === true && <span style={{ color: "rgba(240, 104, 129, 1)" }}>Username Already Exists</span>}
                {usernameError === false && userName !== "" && <span style={{ color: "rgba(36, 153, 62, 1)" }}>Username is available</span>}
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicName">
                <label>
                  Owner Name <span className="text-danger">*</span>
                </label>
                <Form.Control
                  className="p-2"
                  name="shop_owner_name"
                  type="text"
                  placeholder="Owner Name"
                  onKeyDown={(e) => ["!", "#", "$", "%", "^", "&", "*", "(", ")", ":", "'", "/", "`", "{", "}", "[", "]", "~", "+", "@", "_", "-"].includes(e.key) && e.preventDefault()}
                  value={shop_owner_name}
                  onChange={(e) => setShopOwnerName(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">* Please Enter Owner Name</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicEmail">
                <label>
                  Owner's Email <span className="text-danger">*</span>
                </label>
                <Form.Control className="p-2" name="owner_email" type="email" placeholder="example@gmail.com" value={owner_email} onChange={(e) => setEmail(e.target.value)} required />
                <Form.Control.Feedback type="invalid">* Please Enter Valid Email Address</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="12" md="12" sm="12" controlId="formBasicAddress">
                <label>
                  Address <span className="text-danger">*</span>
                </label>
                <Form.Control className="p-2" name="address" type="text" placeholder="Type Address" value={shop_address} onChange={(e) => setShopAddress(e.target.value)} required />
                <Form.Control.Feedback type="invalid">* Please Enter Shop Address</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  Province <span className="text-danger">*</span>
                </label>
                <ReactSelect placeholder="Select Province" options={provinceList} value={province} onChange={(e) => handleprovince(e)} />
                <Form.Control className="p-2" name="province" hidden type="text" value={province?.value > 0 ? province?.value : ""} onChange={() => {}} required />
                <Form.Control.Feedback type="invalid">* Please Select Province</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  City <span className="text-danger">*</span>
                </label>
                <ReactSelect placeholder="Select City" options={cityList} value={city} onChange={(e) => handlecity(e)} />
                <Form.Control className="p-2" name="city" hidden type="text" value={city?.value > 0 ? city?.value : ""} onChange={() => {}} required />
                <Form.Control.Feedback type="invalid">* Please Select City</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicCNIC">
                <label>
                  CNIC Number <span className="text-danger">*</span>
                </label>
                <CnicInput value={owner_CNIC} onChange={(e) => setOwnerCNIC(e.target.value)} placeholder="XXXXX-XXXXXXX-X" required />
                <Form.Control.Feedback type="invalid">* Please Enter Your CNIC</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicNumber">
                <label>
                  Whatsapp Number <span className="text-danger">*</span>
                </label>
                <PhoneInput placeholder="+92-3XX-XXXXXXX" value={owner_whatsapp_number} onChange={(e) => setOwnerWhatsappNumber(e.target.value)} required />
                <Form.Control.Feedback type="invalid">* Please Enter Correct Whatsapp Number</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicEmail">
                <label className="mb-1">
                  Backup Number <span className="text-secondary">(Optional)</span>
                </label>
                <PhoneInput className="p-2" type="number" placeholder="+92-3XX-XXXXXXX" value={owner_backup_phone_number} onChange={(e) => setOwnerBackupPhoneNumber(e.target.value)} pattern="^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$" />
                <Form.Control.Feedback type="invalid">* Please Enter Correct Number</Form.Control.Feedback>
              </Form.Group>
              <Col className="mt-2 mb-3" lg="6" md="6" sm="12">
                <label className="mb-1">
                  Shop Working Days <span className="text-danger">*</span>
                </label>
                <div className={style.multiSelect}>
                  <ReactSelect classNamePrefix="react-select-container" options={daysOptions} isMulti closeMenuOnSelect={false} onChange={handleChange} value={daysField} name="shop_working_days" />
                </div>
                <Form.Control className="p-2" name="workingDays" hidden type="text" value={daysField.length > 0 ? daysField : ""} onChange={() => {}} required />
                <Form.Control.Feedback type="invalid">* Please Select Shop Working Days</Form.Control.Feedback>
              </Col>
            </Row>
            <Map coords={coords} setCoords={setCoords} isLocationSelected={isLocationSelected} setIsLocationSelected={setIsLocationSelected} />
            <div className={`${style.fullWidth} ${style.hr} mt-2`}></div>
            <div className={`${style.fullWidth} my-3`}>
              <div className={`${style.flexRadio}`}>
                <div className={`${style.radiolabel}`}>
                  <Form.Check className={`${style.radio}`} name="trailOrPayment" value="on_trial" type="radio" id="onTrial" aria-label="radio Trial" onClick={handleRadio} checked={trialOrPayment === "onTrial"} />
                  <label htmlFor="Trial">On Trial</label>
                </div>
                <div className={`${style.radiolabel}`}>
                  <Form.Check className={`${style.radio}`} name="trailOrPayment" value="on_payment" type="radio" id="onPayment" aria-label="radio Payment" onClick={handleRadio} checked={trialOrPayment === "onPayment"} />
                  <label htmlFor="Payment">On Payment</label>
                </div>
              </div>
              {trialPaymentError && (
                <div className="mt-2" style={{ color: "#DC3545" }}>
                  * Please select On Trial or On Payment
                </div>
              )}
            </div>
            <Row className={`mb-3 ${style.trialDateInput}`}>
              {trialOrPayment === "onTrial" && (
                <Form.Group as={Col} lg="6" md="6" sm="12">
                  <label className="mb-1">
                    On Trial Till <span className="text-danger">*</span>
                  </label>
                  <Form.Control className="p-2" type="date" placeholder="Select Date" min={maxDate} value={trial_till_date} onChange={(e) => setTrialTillDate(e.target.value)} required={on_trial === true ? true : false} />
                  <Form.Control.Feedback type="invalid">* Please Enter Trial Till Date</Form.Control.Feedback>
                </Form.Group>
              )}
              {trialOrPayment === "onPayment" && (
                <>
                  <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                    <label className="mb-1">
                      Plans <span className="text-danger">*</span>
                    </label>
                    <ReactSelect
                      className="text-capitalize"
                      placeholder="Select Plan"
                      options={plans}
                      onChange={(e) => {
                        setSelectPlan(e);
                      }}
                    />
                    <Form.Control className="p-2" name="plan" hidden type="text" value={Object.keys(selectPlan).length > 0 ? selectPlan : null} onChange={() => {}} required={on_payment === true ? true : false} />
                    <Form.Control.Feedback type="invalid">* Please Select Plan</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                    <label className="mb-1">
                      Billing Period <span className="text-danger">*</span>{" "}
                    </label>
                    <ReactSelect
                      placeholder="Select Billing Period"
                      options={[
                        { value: "monthly", label: "Monthly" },
                        { value: "quarterly", label: "Quarterly" },
                        { value: "yearly", label: "Yearly" },
                      ]}
                      defaultValue={billingPeriod}
                      value={billingPeriod}
                      onChange={(e) => setBillingPeriod(e)}
                    />
                    <Form.Control className="p-2" name="billingPeriod" hidden type="text" value={Object.keys(billingPeriod).length > 0 ? billingPeriod : null} onChange={() => {}} required={on_payment === true ? true : false} />
                    <Form.Control.Feedback type="invalid">* Please Select Billing Period</Form.Control.Feedback>
                  </Form.Group>
                  <div>
                    <Form.Group className={`${style.hr} mt-2 mb-3`}></Form.Group>
                  </div>
                  {selectPlan.value === "custom" && (
                    <>
                      <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                        <label className="mb-1">
                          Plan Amount <span className="text-danger">*</span>
                        </label>
                        <Form.Control className="p-2" placeholder="Rs. 1000" name="plan" type="text" value={planAmount} onChange={(e) => setPlanAmount(e.target.value.replace(/\D/g, ""))} required={selectPlan.value === "custom" ? true : false} />
                        <Form.Control.Feedback type="invalid">* Please Enter Plan Amount</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                        <label className="mb-1">
                          MarketPlace Items Limit <span className="text-danger">*</span>
                        </label>
                        <Form.Control className="p-2" placeholder="eg. 1000" name="plan" type="text" value={itemsLimit} onChange={(e) => setItemsLimit(e.target.value.replace(/\D/g, ""))} required={selectPlan.value === "custom" ? true : false} />
                        <Form.Control.Feedback type="invalid">* Please Enter MarketPlace Items Limit</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                        <label className="mb-1">
                          Emails Limit <span className="text-danger">*</span>
                        </label>
                        <Form.Control className="p-2" placeholder="eg. 1000" name="plan" type="text" value={emailsLimit} onChange={(e) => setEmailsLimit(e.target.value.replace(/\D/g, ""))} required={selectPlan.value === "custom" ? true : false} />
                        <Form.Control.Feedback type="invalid">* Please Enter Emails Limit</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                        <label className="mb-1">
                          Locations Limit <span className="text-danger">*</span>
                        </label>
                        <Form.Control className="p-2" placeholder="eg. 1000" name="plan" type="text" value={locationLimit} onChange={(e) => setLocationLimit(e.target.value.replace(/\D/g, ""))} required={selectPlan.value === "custom" ? true : false} />
                        <Form.Control.Feedback type="invalid">* Please Enter Locations Limit</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                        <label className="mb-1">
                          Sms Limit <span className="text-danger">*</span>
                        </label>
                        <Form.Control className="p-2" placeholder="eg. 1000" name="plan" type="text" value={smsLimit} onChange={(e) => setSmsLimit(e.target.value.replace(/\D/g, ""))} required={selectPlan.value === "custom" ? true : false} />
                        <Form.Control.Feedback type="invalid">* Please Enter Sms Limit</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                        <label className="mb-1">
                          Secondary Users Limit <span className="text-danger">*</span>
                        </label>
                        <Form.Control className="p-2" placeholder="eg. 1000" name="plan" type="text" value={secondaryUsersLimit} onChange={(e) => setSecondaryUsersLimit(e.target.value.replace(/\D/g, ""))} required={selectPlan.value === "custom" ? true : false} />
                        <Form.Control.Feedback type="invalid">* Please Enter Secondary Users Limit</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12">
                        <label className="mb-1 text-capitalize">
                          {billingPeriod.value} Total <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className="p-2"
                          disabled
                          placeholder="Rs. 1000"
                          name="plan"
                          type="text"
                          value={billingPeriod.value === "yearly" ? Math.round(yearlyTotal) : billingPeriod.value === "quarterly" ? Math.round(quarterlyTotal) : Math.round(monthlyTotal)}
                          onChange={() => {}}
                          required={selectPlan.value === "custom" ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">* Please Enter {billingPeriod.value} Total</Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                </>
              )}
            </Row>
            <div className={`${style.fullWidth} m-0`}>
              <Form.Label>
                Upload Logo <span className="text-secondary">(Optional)</span>
              </Form.Label>
              <ImageUploader ref={ImageRef} selectedFiles={image} setSelectedFiles={setImage} />
            </div>
            <div className={`mb-4 ${style.fullWidth}`}>
              <Button className="w-100" type="submit" disabled={isClicked}>
                {isClicked ? <Loader /> : "Register Shop"}
              </Button>
            </div>
          </Form>
        </FormCard>
      </div>
    </div>
  );
};
export default RegisterShop;
