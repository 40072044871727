import React from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import style from "../styles/Notification.module.css";
import ReadNotifications from "../Components/NotificationDropdown/ReadNotifications";
import UnreadNotifications from "../Components/NotificationDropdown/UnreadNotifications";
import AllNotifications from "../Components/NotificationDropdown/AllNotifications";
const SalesNotifications = () => {
  return (
    <Container fluid>
      <Row className="mx-md-5 p-3 fs-4">Notifications</Row>
      <div className="bg-white p-4 border-1 rounded mx-md-5">
        <Row style={{ marginBottom: "-3rem" }} className="mt-3">
          <Col>
            <Tabs defaultActiveKey="All" className={`${style.navtabs} `}>
              <Tab eventKey="All" title="All" className="text-secondary mb-5 ">
                <AllNotifications />
              </Tab>
              <Tab eventKey="Read" title="Read" className="text-secondary mb-5 ">
                <ReadNotifications />
              </Tab>
              <Tab eventKey="Unread" title={`Unread`} className="text-secondary mb-5 ">
                <UnreadNotifications />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SalesNotifications;
