import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const config = {
  headers: {
    Authorization: localStorage.getItem("refreshToken"),
  },
};
// subscription

export function SubmitTransaction(data) {
  return api.post(`/transactions/lock`, data, { withCredentials: true });
}

// uploadImage

export function UPloadImage(data) {
  return api.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images/transactions`, data, { withCredentials: true });
}

export function RequestUpdate(id, status) {
  return api.put(
    `/become-seller/${id}`, //registration request id
    { status: status },
    { withCredentials: true }
  );
}
export function PayForNextPlan(transaction) {
  const id = transaction.shop_id;
  return api.post(`/transactions/pay-for-next-plan/${id}`, { transaction }, { withCredentials: true });
}

export function ShiftToPaidPlan(data) {
  return api.post(`/transactions/shift-to-paid-plan/${data?.shop_id}`, data, { withCredentials: true });
}

export function addExtensionRequest(data) {
  return api.post(`/addons/extension-request`, data, { withCredentials: true });
}

export function addonsRequest(data) {
  return api.post(`addons/request`, { shop_id: data?.shop_id, requested_items: data?.requested_items, is_paid: data?.is_paid }, { withCredentials: true });
}
