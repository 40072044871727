import React, { useEffect, useLayoutEffect } from "react";
import { Navigate, Route, Routes, Outlet, useLocation } from "react-router-dom";
import Login from "./Pages/Login";
import RegisterShop from "./Pages/RegisterShop";
import { useSelector, useDispatch } from "react-redux";
import DashboardLayout from "./Layout/DashboardLayout";
import Cookies from "./Features/Cookies";
import { currentUser, authActions } from "./Features/authSlice";
import Home from "./Pages/Home";
import MyShopkeepers from "./Pages/MyShopkeepers/MyShopkeepers";
import SellerRegistrationRequests from "./Pages/SellerRegistrationRequests/SellerRegistrationRequests";
import Invoice from "./Components/Invoice";
import Notifications from "./Pages/SalesNotifications";
import unreadNotificationIcon from "./assets/svg/unreadNotificationIcon.svg";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { notificationActions } from "./Features/notificationSlice";

const RedirectComponent = () => {
  const token = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN);
  const location = useLocation();
  const dispatch = useDispatch();
  const refreshToken = localStorage.getItem("refreshToken");
  const socketRef = React.useRef();

  useLayoutEffect(() => {
    if (token) {
      dispatch(currentUser(refreshToken));
    } else {
      dispatch(authActions.logout());
    }
  }, [dispatch, refreshToken, token, location]);

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    const CustomToast = ({ notificationData }) => (
      <div className="d-flex justify-content-around">
        <img src={unreadNotificationIcon} style={{ marginRight: "5px" }} />
        {notificationData.body}
      </div>
    );
    const notificationCountCallback = (notificationData) => {
      dispatch(notificationActions.notificationCount(notificationData));
    };
    const expiredSubscriptionCallback = (notificationData) => {
      toast(<CustomToast notificationData={notificationData} />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        pauseOnHover: false,
        closeButton: true,
        className: "toastMsg",
      });
    };
    if (token && token !== "undefined") {
      if (token && token !== "undefined") {
        if (!socketRef.current) {
          socketRef.current = io(process.env.REACT_APP_API_URL, {
            transports: ["polling"],
            auth: {
              token,
            },
          });
        }
        const socket = socketRef.current;
        socket.on("notificationCount", notificationCountCallback);
        socket.on("expiredSubscription", expiredSubscriptionCallback);

        return () => {
          // Disconnect the socket when the component unmounts
          socket.off("notificationCount", notificationCountCallback);
          socket.off("expiredSubscription", expiredSubscriptionCallback);
        };
      }
    } else {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    }
  }, [token]);
  return (
    <>
      <Routes>
        {user && token ? (
          <Route
            path="*"
            element={
              <Routes>
                <Route path="/" element={<DashboardLayout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="register-shop" element={<RegisterShop />} />
                  <Route path="shopkeepers" element={<MyShopkeepers />} />
                  <Route path="seller-registration" element={<SellerRegistrationRequests />} />
                  {/* <Route path='edit' element={<EditShop />} /> */}
                  <Route path="shopkeepers/payment-invoice/:id" element={<Invoice />} />
                  <Route path="notifications" element={<Notifications />} />
                </Route>
                <Route exact path="/login" element={<Navigate to="/" />} />
                <Route exact path="*" element={<Navigate to="/" />} />
              </Routes>
            }
          />
        ) : (
          <Route
            path="*"
            element={
              <Routes>
                <Route exact path="/login" element={<Login />} />
                {/* <Route path='/forget-password' element={<ForgetPassword />} />
                                    <Route path='/set-password' element={<SetPassword />} />
                                    <Route path='/set-password/:token' element={<Loading />} /> */}
                <Route exact path="*" element={token ? refreshToken ? <Outlet /> : <Navigate to="/login" /> : <Navigate to="/login" />} />
              </Routes>
            }
          />
        )}
      </Routes>
    </>
  );
};

export default RedirectComponent;
